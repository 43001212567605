import axios from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import { authTokenName, refreshTokenName } from '../utils';
import { setAuthToken } from '../Admin/adminSlice';

export const refreshAccessToken = async () => {
  try {
    const refreshToken = window.localStorage.getItem(refreshTokenName);
    if (!refreshToken) throw new Error('no-refresh-token');

    const data = { token: refreshToken };
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, data);
    return {
      accessToken: res.data.data.accessToken,
      refreshToken: res.data.data.refreshToken,
    };
  } catch (err) {
    window.location.href = '/?redirected=true';
    // if (err.message !== 'no-refresh-token') window.location.href = '/?redirected=true';
    return {};
  }
};

type Callback = (accessToken: string) => void;

export const axiosInterceptorRefreshToken = (dispatch: Dispatch<any>) => {
  // see inspiration at - https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
  // and https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c

  let isRefreshing = false;
  let subscribers: Callback[] = [];

  const subscribeTokenRefresh = (cb: Callback) => {
    subscribers.push(cb);
  };

  const onTokenRefresh = (accessToken: string) => {
    subscribers.map((cb: Callback) => cb(accessToken));
    subscribers = [];
  };

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        error.response.data.message !== 'invalidCredentials' &&
        // eslint-disable-next-line no-underscore-dangle
        !originalRequest._retry
      ) {
        if (!isRefreshing) {
          isRefreshing = true;
          // eslint-disable-next-line consistent-return
          refreshAccessToken().then((tokens) => {
            if (tokens && tokens.refreshToken && tokens.accessToken) {
              const { accessToken, refreshToken } = tokens;
              isRefreshing = false;
              onTokenRefresh(accessToken);
              window.localStorage.setItem(refreshTokenName, refreshToken);
              window.localStorage.setItem(authTokenName, accessToken);
              dispatch(setAuthToken(accessToken));
              // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
              // return axios(originalRequest);
            } else {
              return Promise.reject(error);
            }
          });
        }

        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true;

        return new Promise((resolve, reject) => {
          subscribeTokenRefresh((accessToken: string) => {
            try {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              resolve(axios(originalRequest));
            } catch (err) {
              reject(err);
            }
          });
        });
      }
      return Promise.reject(error);
    }
  );
};
