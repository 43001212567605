import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { RootState } from '../../store/store';
import { authTokenName, createAsyncAction, createCustomAsyncThunk, refreshTokenName } from '../utils';

function decodeJwt(token: string): { sub: number } {
  return jwt_decode(token);
}

const sliceName = 'admin';

const asyncActions = {
  postSignout: 'postSignout',
};

type AdminState = {
  id: number | null;
  status: 'idle' | 'loading' | 'failed';
  errorMessages: { [key: string]: string[] };
  password: string | null;
  authToken: string;
  note: string;
};

const initialState: AdminState = {
  id: null,
  status: 'idle',
  errorMessages: {},
  password: null,
  authToken: '',
  note: '',
};

type LoginParams = {
  data: {
    email: string;
    password: string;
    path?: string;
  };
};

type LoginRes = {
  id: number;
  accessToken: string;
  refreshToken: string;
};

export const postLogin = createCustomAsyncThunk<LoginRes, LoginParams>('admin/postLogin', {
  method: 'POST',
  url: () => `${process.env.REACT_APP_API_URL}/auth/login`,
});

type PostRegRes = {
  accessToken: string;
  refreshToken: string;
};
type PostRegParams = { data: { firstName: string; lastName: string; email: string; password: string; path?: string } };

export const postRegister = createCustomAsyncThunk<PostRegRes, PostRegParams>('admin/postRegister', {
  method: 'POST',
  url: `${process.env.REACT_APP_API_URL}/auth/register`,
});

type PostSignoutRes = {
  refreshToken: string;
};

type PostSignoutParams = {
  data: {
    refreshToken: string;
    userId: number;
  };
};

export const postSignout = createCustomAsyncThunk<PostSignoutRes, PostSignoutParams>(
  createAsyncAction(sliceName, asyncActions.postSignout),
  {
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/auth/logout`,
  }
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearError(state, _) {
      state.errorMessages = {};
      state.status = 'idle';
    },
    signout() {
      localStorage.removeItem(authTokenName);
      localStorage.removeItem(refreshTokenName);
      return initialState;
    },
    setAuthToken(state, action: PayloadAction<string>) {
      state.authToken = action.payload;
      state.id = decodeJwt(action.payload).sub;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.status = 'idle';
        state.authToken = action.payload.accessToken;
        state.id = decodeJwt(action.payload.accessToken).sub;
        localStorage.setItem(authTokenName, action.payload.accessToken);
        localStorage.setItem(refreshTokenName, action.payload.refreshToken);
      })
      .addCase(postLogin.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(postRegister.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postRegister.fulfilled, (state, action) => {
        state.status = 'idle';
        state.authToken = action.payload.accessToken;
        localStorage.setItem(authTokenName, action.payload.accessToken);
        localStorage.setItem(refreshTokenName, action.payload.refreshToken);
        state.id = decodeJwt(action.payload.accessToken).sub;
      })
      .addCase(postRegister.rejected, (state, action) => {
        state.status = 'failed';

        if (!action.payload) return;

        state.errorMessages = action.payload.errMessages;
      })
      .addCase(postSignout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postSignout.fulfilled, () => {
        localStorage.removeItem(authTokenName);
        localStorage.removeItem(refreshTokenName);
        return initialState;
      })
      .addCase(postSignout.rejected, () => {
        // Even though backend request fails, user still should be logged out on signout click
        localStorage.removeItem(authTokenName);
        localStorage.removeItem(refreshTokenName);
        // state.authToken = '';
        return initialState;
      });
  },
});

export const selectIsAuth = (state: RootState) => !!state.admin.authToken;
export const { clearError, signout, setAuthToken } = adminSlice.actions;
export const adminReducer = adminSlice.reducer;
