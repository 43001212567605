import { createSlice } from '@reduxjs/toolkit';
import { createAsyncAction, createCustomAsyncThunk } from '../../utils';
import { Order } from './types';
import { RootState } from '../../../store/store';

const sliceName = 'order';

const asyncActions = {
  getOrderByProjectPath: 'getOrderByProjectPath',
  getOrderById: 'getOrderById',
  patchOrderByProjectPath: 'patchOrderByProjectPath',
  postFapiOrder: 'postFapiOrder',
};

type OrderState = {
  status: 'idle' | 'loading' | 'failed';

  areSocksIncluded: boolean;
} & Partial<Order>;

const initialState: OrderState = {
  status: 'idle',

  country: 'CZ',
  currency: 'CZK',
  useShippingInfo: false,
  useCompanyInfo: false,
  areSocksIncluded: false,
};

// GET
type GetOrderByPathRes = Order;
type GetOrderByPathArgs = { projectPath: string };

export const getOrderByProjectPath = createCustomAsyncThunk<GetOrderByPathRes, GetOrderByPathArgs>(
  createAsyncAction(sliceName, asyncActions.getOrderByProjectPath),
  {
    method: 'GET',
    url: ({ projectPath }) => `${process.env.REACT_APP_API_URL}/projects/${projectPath}/order`,
  }
);

type GetOrderRes = Order;
type GetOrderArgs = { orderId: number };

export const getOrderById = createCustomAsyncThunk<GetOrderRes, GetOrderArgs>(
  createAsyncAction(sliceName, asyncActions.getOrderById),
  {
    method: 'GET',
    url: ({ orderId }) => `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
  }
);

// PATCH
type PatchOrderRes = Order;
type PatchOrderArgs = { orderId: number; data: Partial<Order> };

export const patchOrder = createCustomAsyncThunk<PatchOrderRes, PatchOrderArgs>(
  createAsyncAction(sliceName, asyncActions.patchOrderByProjectPath),
  {
    method: 'PATCH',
    url: ({ orderId }) => `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
  }
);

export const orderSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getOrderByProjectPath.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOrderByProjectPath.fulfilled, (state, action) => {
        state.status = 'idle';

        state.id = action.payload.id;
        state.userId = action.payload.userId;
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.notes = action.payload.notes;
        state.company = action.payload.company;
        state.useCompanyInfo = action.payload.useCompanyInfo;
        state.ico = action.payload.ico;
        state.dic = action.payload.dic;
        state.street = action.payload.street;
        state.city = action.payload.city;
        state.postalCode = action.payload.postalCode;
        state.country = action.payload.country ?? 'CZ';
        state.useShippingInfo = action.payload.useShippingInfo;
        state.shippingFirstName = action.payload.shippingFirstName;
        state.shippingLastName = action.payload.shippingLastName;
        state.shippingStreet = action.payload.shippingStreet;
        state.shippingCity = action.payload.shippingCity;
        state.shippingPostalCode = action.payload.shippingPostalCode;
        state.shippingCountry = action.payload.shippingCountry ?? 'CZ';
        state.shippingMethodId = action.payload.shippingMethodId;
        state.paymentType = action.payload.paymentType;
        state.bank = action.payload.bank;
        state.primaryPurposeId = action.payload.primaryPurposeId;
        state.primaryPurposeText = action.payload.primaryPurposeText;
        state.primaryPurposeChecked = action.payload.primaryPurposeChecked;
        state.termsAccepted = action.payload.termsAccepted;
        state.termsText = action.payload.termsText;
        state.price = action.payload.price;
        state.currency = action.payload.currency ?? 'CZK';
        state.fapiOrderId = action.payload.fapiOrderId;

        state.areSocksIncluded = action.payload.project.areSocksIncluded ?? false;
      })
      .addCase(getOrderByProjectPath.rejected, (state) => {
        state.status = 'failed';

        state.id = undefined;
        state.userId = undefined;
        state.firstName = undefined;
        state.lastName = undefined;
        state.email = undefined;
        state.phone = undefined;
        state.notes = undefined;
        state.company = undefined;
        state.useCompanyInfo = undefined;
        state.ico = undefined;
        state.dic = undefined;
        state.street = undefined;
        state.city = undefined;
        state.postalCode = undefined;
        state.country = undefined;
        state.useShippingInfo = false;
        state.shippingFirstName = undefined;
        state.shippingLastName = undefined;
        state.shippingStreet = undefined;
        state.shippingCity = undefined;
        state.shippingPostalCode = undefined;
        state.shippingCountry = undefined;
        state.shippingMethodId = undefined;
        state.paymentType = undefined;
        state.bank = undefined;
        state.primaryPurposeId = undefined;
        state.primaryPurposeText = undefined;
        state.primaryPurposeChecked = undefined;
        state.termsAccepted = undefined;
        state.termsText = undefined;
        state.price = undefined;
        state.currency = 'CZK';
        state.fapiOrderId = undefined;

        state.areSocksIncluded = false;
      })
      .addCase(patchOrder.fulfilled, (state, action) => {
        state.status = 'idle';
        state.id = action.payload.id;
        state.userId = action.payload.userId;
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.notes = action.payload.notes;
        state.company = action.payload.company;
        state.useCompanyInfo = action.payload.useCompanyInfo;
        state.ico = action.payload.ico;
        state.dic = action.payload.dic;
        state.street = action.payload.street;
        state.city = action.payload.city;
        state.postalCode = action.payload.postalCode;
        state.country = action.payload.country ?? 'CZ';
        state.useShippingInfo = action.payload.useShippingInfo;
        state.shippingFirstName = action.payload.shippingFirstName;
        state.shippingLastName = action.payload.shippingLastName;
        state.shippingStreet = action.payload.shippingStreet;
        state.shippingCity = action.payload.shippingCity;
        state.shippingPostalCode = action.payload.shippingPostalCode;
        state.shippingCountry = action.payload.shippingCountry ?? 'CZ';
        state.shippingMethodId = action.payload.shippingMethodId;
        state.paymentType = action.payload.paymentType;
        state.bank = action.payload.bank;
        state.primaryPurposeId = action.payload.primaryPurposeId;
        state.primaryPurposeText = action.payload.primaryPurposeText;
        state.primaryPurposeChecked = action.payload.primaryPurposeChecked;
        state.termsAccepted = action.payload.termsAccepted;
        state.termsText = action.payload.termsText;
        state.price = action.payload.price;
        state.currency = action.payload.currency ?? 'CZK';
        state.fapiOrderId = action.payload.fapiOrderId;
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.status = 'idle';
        state.id = action.payload.id;
        state.userId = action.payload.userId;
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.notes = action.payload.notes;
        state.company = action.payload.company;
        state.useCompanyInfo = action.payload.useCompanyInfo;
        state.ico = action.payload.ico;
        state.dic = action.payload.dic;
        state.street = action.payload.street;
        state.city = action.payload.city;
        state.postalCode = action.payload.postalCode;
        state.country = action.payload.country ?? 'CZ';
        state.useShippingInfo = action.payload.useShippingInfo;
        state.shippingFirstName = action.payload.shippingFirstName;
        state.shippingLastName = action.payload.shippingLastName;
        state.shippingStreet = action.payload.shippingStreet;
        state.shippingCity = action.payload.shippingCity;
        state.shippingPostalCode = action.payload.shippingPostalCode;
        state.shippingCountry = action.payload.shippingCountry ?? 'CZ';
        state.shippingMethodId = action.payload.shippingMethodId;
        state.paymentType = action.payload.paymentType;
        state.bank = action.payload.bank;
        state.primaryPurposeId = action.payload.primaryPurposeId;
        state.primaryPurposeText = action.payload.primaryPurposeText;
        state.primaryPurposeChecked = action.payload.primaryPurposeChecked;
        state.termsAccepted = action.payload.termsAccepted;
        state.termsText = action.payload.termsText;
        state.price = action.payload.price;
        state.currency = action.payload.currency ?? 'CZK';
        state.fapiOrderId = action.payload.fapiOrderId;

        state.areSocksIncluded = action.payload.project.areSocksIncluded ?? false;
      })
      .addCase(getOrderById.rejected, (state) => {
        state.status = 'failed';

        state.id = undefined;
        state.userId = undefined;
        state.firstName = undefined;
        state.lastName = undefined;
        state.email = undefined;
        state.phone = undefined;
        state.notes = undefined;
        state.company = undefined;
        state.ico = undefined;
        state.dic = undefined;
        state.street = undefined;
        state.city = undefined;
        state.postalCode = undefined;
        state.country = undefined;
        state.useShippingInfo = false;
        state.useCompanyInfo = false;
        state.shippingFirstName = undefined;
        state.shippingLastName = undefined;
        state.shippingStreet = undefined;
        state.shippingCity = undefined;
        state.shippingPostalCode = undefined;
        state.shippingCountry = undefined;
        state.shippingMethodId = undefined;
        state.paymentType = undefined;
        state.bank = undefined;
        state.primaryPurposeId = undefined;
        state.primaryPurposeText = undefined;
        state.primaryPurposeChecked = undefined;
        state.termsAccepted = undefined;
        state.termsText = undefined;
        state.price = undefined;
        state.currency = 'CZK';
        state.fapiOrderId = undefined;

        state.areSocksIncluded = false;
      }),
});

export const orderPaymentFormDefaults = (state: RootState) => {
  const primaryPurpose = state.fapi.purposes.find((purpose) => purpose.isPrimary);
  const shippingMethodId = state.order?.shippingMethodId;
  const paymentType = state.order?.paymentType;
  const bank = state.order?.bank;
  const primaryPurposeId = primaryPurpose?.id;
  const primaryPurposeText = primaryPurpose?.html ?? '';
  const primaryPurposeChecked = state.order?.primaryPurposeChecked || false;
  const termsAccepted = false;
  const termsText = '';

  const notes = state.order?.notes;

  return {
    shippingMethodId,
    paymentType,
    bank,
    primaryPurposeId,
    primaryPurposeText,
    primaryPurposeChecked,
    termsAccepted,
    termsText,
    notes,
  };
};

export const orderInfoFormDefaults = (state: RootState) => {
  const defaultFirstName = state.accountSettings.firstName;
  const defaultLastName = state.accountSettings.lastName;

  const billingAddress = state.accountSettings.addresses.find((address) => address.type === 'billing');
  const shippingAddress = state.accountSettings.addresses.find((address) => address.type === 'shipping');

  const firstName = state.order?.firstName ?? billingAddress?.firstName ?? defaultFirstName ?? '';
  const lastName = state.order?.lastName ?? billingAddress?.lastName ?? defaultLastName ?? '';
  const email = state.order?.email ?? state.accountSettings.email;
  const phone = state.order?.phone ?? billingAddress?.phone;
  const useCompanyInfo = state.order?.useCompanyInfo ?? false;
  const company = state.order?.company ?? billingAddress?.companyName;
  const ico = state.order?.ico ?? billingAddress?.ico;
  const dic = state.order?.dic ?? billingAddress?.dic;
  const street = state.order?.street ?? billingAddress?.street;
  const city = state.order?.city ?? billingAddress?.city;
  const postalCode = state.order?.postalCode ?? billingAddress?.postalCode;

  const useShippingInfo = state.order?.useShippingInfo ?? false;
  const shippingFirstName = state.order?.shippingFirstName ?? shippingAddress?.firstName;
  const shippingLastName = state.order?.shippingLastName ?? shippingAddress?.lastName;
  const shippingStreet = state.order?.shippingStreet ?? shippingAddress?.street;
  const shippingCity = state.order?.shippingCity ?? shippingAddress?.city;
  const shippingPostalCode = state.order?.shippingPostalCode ?? shippingAddress?.postalCode;

  const notes = state.order?.notes;

  return {
    firstName,
    lastName,
    email,
    phone,
    useCompanyInfo,
    company,
    ico,
    dic,
    street,
    city,
    postalCode,
    notes,
    useShippingInfo,
    shippingFirstName,
    shippingLastName,
    shippingStreet,
    shippingCity,
    shippingPostalCode,
  };
};

export const getSelectedShippingMethod = (state: RootState) => {
  const { shippingMethods } = state.fapi;
  const { shippingMethodId, currency } = state.order;

  const shippingMethod = shippingMethods.find(({ id }) => id === shippingMethodId) ?? null;
  const shippingMethodPrice = shippingMethod?.addressPrices
    .map((ap) => ap.prices.filter((p) => p.currencyCode === currency).map((p) => p.price))
    .reduce((prev, current) => current, [])
    .reduce((prev, current) => current, 0);

  return {
    ...shippingMethod,
    price: shippingMethodPrice ?? 0,
  };
};

export const orderReducer = orderSlice.reducer;
