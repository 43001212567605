export const getTrainingSetColor = (
  projectColors: {
    isActive: boolean;
    value: string;
  }[]
) => {
  let i = projectColors.length - 1;
  let color;

  for (; i >= 0; i--) {
    if (projectColors[i].isActive) {
      color = projectColors[i];
      break;
    }
  }

  if (!color) {
    // console.error('No active training set!');
    return { value: '#FFFFFF', isActive: true };
  }
  return color;
};
