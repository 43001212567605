import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { ConfigSectionIds, FontOption, JerseyType } from './types';
import { createProject } from './TeamNameConfig/teamNameSlice';
import { fetchProjectByPath } from './sharedActions';
import { patchProject } from './reusableActions';
import { createAsyncAction, createUrlToStaticFile } from '../utils';

const sliceName = 'jerseyConfig';

const asyncActions = {
  fetchSocksList: 'fetchSocksList',
  fetchSockPattern: 'fetchSockPattern',
  fetchInitVals: 'fetchInitVals',
  fetchProject: 'fetchProject',
  patchProjectTouchedSectionId: 'patchProjectTouchedSectionId',
};

export type ColorOption = {
  id: number;
  value: string;
  nameCs: string;
  nameEn: string;
  isDefault: boolean;
};

export type ColorOptions = (ColorOption | any)[];

export type ConfigSection = {
  id: ConfigSectionIds;
  name: string;
  isValid: boolean;
};

export type ConfigSections = {
  id: ConfigSectionIds;
  name: string;
  isValid: boolean;
}[];

interface MatchJerseyConfigState {
  projectPath: string;
  configSections: {
    sections: ConfigSections;
    activeSectionId: number;
    jerseyType: JerseyType | undefined;
  };
  fontOptions: (FontOption | any)[];
  status: 'idle' | 'loading' | 'failed';
  colorOptions: ColorOptions;
  lastTouchedConfigSectionId: number;
}

const initialState: MatchJerseyConfigState = {
  projectPath: '',
  configSections: {
    sections: [],
    activeSectionId: 0,
    jerseyType: undefined,
  },
  status: 'idle',
  colorOptions: [],
  fontOptions: [],
  lastTouchedConfigSectionId: 0,
};

// THUNKS ---------------------------

type PatchTouchedProjectParams = {
  path: string;
  lastTouchedConfigSectionId: number;
};

type PatchTouchedProjectRes = {
  path: string;
  lastTouchedConfigSectionId: number;
};

export const patchProjectTouchedSectionId = patchProject<PatchTouchedProjectRes, PatchTouchedProjectParams>(
  createAsyncAction(sliceName, asyncActions.patchProjectTouchedSectionId)
);

// END OF THUNKS -------------------

function createItemNameByLang<T extends object>(item: T, lang: string | undefined = 'cs'): string {
  const nameKey: keyof T = `name${lang.charAt(0).toUpperCase() + lang.slice(1)}` as keyof T;
  const name = item[nameKey];
  if (typeof name === 'string') return name as string;
  return '';
}

export const jerseyConfigSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    changeJerseyConfigType(
      state,
      action: PayloadAction<{
        sections: { id: ConfigSectionIds; name: string }[];
        type: JerseyType;
      }>
    ) {
      state.configSections.sections = action.payload.sections.map(({ id, name }) => ({ id, name, isValid: false }));

      // SPRÁVNÁ LOGIKA - ZAKOMENTOVÁNA KVŮLI LEHČÍMU VÝVOJI
      state.configSections.activeSectionId = state.configSections.sections.findIndex((section) => !section.isValid);

      // state.configSections.activeSectionId = 0;
    },
    changeActiveConfigSectionId(state, action: PayloadAction<number>) {
      state.configSections.activeSectionId = action.payload;
    },
    addValidSection(state, action: PayloadAction<number>) {
      const sectionName = state.configSections.sections[action.payload].id as string;

      if (state.configSections.sections.length === 0) return;

      state.configSections.sections = state.configSections.sections.map((section: ConfigSection) => {
        if (section.id === sectionName) return { ...section, isValid: true };
        return { ...section };
      });
    },
    removeValidSection(state, action: PayloadAction<number>) {
      const sectionName = state.configSections.sections[action.payload].id as string;

      state.configSections.sections = state.configSections.sections.map((section: ConfigSection) => {
        if (section.id === sectionName) return { ...section, isValid: false };
        return { ...section };
      });
    },
    setProjectPath(state, action: PayloadAction<string>) {
      state.projectPath = action.payload;
    },
    changeLatestTouchedConfigSectionId(state, action: PayloadAction<number>) {
      state.lastTouchedConfigSectionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectByPath.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProjectByPath.fulfilled, (state, action) => {
        const { lastTouchedConfigSectionId } = action.payload;
        state.status = 'idle';
        state.lastTouchedConfigSectionId = lastTouchedConfigSectionId;
        state.colorOptions = action.payload.colors.map((color: ColorOption) => color);
        state.configSections.activeSectionId = lastTouchedConfigSectionId;
        state.fontOptions = action.payload.fonts.map((font) => ({
          name: font.name,
          id: font.id,
          sampleText: font.sampleText,
          url: createUrlToStaticFile({
            id: font.staticFile.id,
            folderName: font.staticFile.folderName,
            ext: font.staticFile.extension,
          }),
          isDefault: font.isDefault,
        }));
        state.configSections.sections = state.configSections.sections.map((section, index) => {
          // index === 0 - first sections is team name. If there is project to fetch, it definitely has a team name
          // that is why first config section is definitely valid on fetch
          if (index < lastTouchedConfigSectionId || index === 0) return { ...section, isValid: true };
          return { ...section, isValid: false };
        });
      })
      .addCase(fetchProjectByPath.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.projectPath = action.payload.path;
      })
      .addCase(patchProjectTouchedSectionId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchProjectTouchedSectionId.fulfilled, (state, action) => {
        state.status = 'idle';
        const { lastTouchedConfigSectionId } = action.payload;
        state.lastTouchedConfigSectionId = lastTouchedConfigSectionId;
      })
      .addCase(patchProjectTouchedSectionId.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectColorOptionsByLanguage = (state: RootState) =>
  state.matchJerseyConfig.colorOptions.map((option: ColorOption) => ({
    id: option.id,
    value: option.value,
    name: createItemNameByLang(option),
    isDefault: option.isDefault,
  }));

export const {
  resetState,
  changeJerseyConfigType,
  addValidSection,
  removeValidSection,
  changeActiveConfigSectionId,
  setProjectPath,
  changeLatestTouchedConfigSectionId,
} = jerseyConfigSlice.actions;

export const jerseyConfigReducer = jerseyConfigSlice.reducer;
