import axios, { AxiosResponse } from 'axios';
import { useReducer, useCallback, Reducer } from 'react';

export interface HttpError extends Error {
  status?: number;
}

const initialState = {
  loading: false,
  error: undefined,
  data: undefined,
  identifier: undefined,
};

type Action<T> =
  | { type: 'send'; identifier: string }
  | { type: 'response'; resData: T }
  | { type: 'error'; message: string; status: number }
  | { type: 'clear' };

type SimpleHttpState<T> = {
  loading: boolean;
  error?: HttpError;
  data?: T;
  identifier?: string;
};

const httpReducer = <T>(httpState: SimpleHttpState<T>, action: Action<T>): SimpleHttpState<T> => {
  switch (action.type) {
    case 'send':
      return {
        loading: true,
        error: undefined,
        data: undefined,
        identifier: action.identifier,
      };
    case 'response':
      return {
        ...httpState,
        loading: false,
        data: action.resData,
      };
    case 'error':
      return {
        ...httpState,
        loading: false,
        error: { name: 'http-err', message: action.message, status: action.status },
      };
    case 'clear':
      return initialState;
    default:
      throw new Error('Should not be reached!');
  }
};

type SendRequestParams = {
  url: string;
  method: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET';
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  body: any;
  reqIdentifer: string;
};

const useHttp = <ResData>() => {
  const [httpState, dispatchHttp] = useReducer<Reducer<SimpleHttpState<ResData>, Action<ResData>>>(
    httpReducer,
    initialState
  );

  const clear = useCallback(() => dispatchHttp({ type: 'clear' }), []);

  const sendRequest = useCallback(async ({ url, method, body, reqIdentifer }: SendRequestParams) => {
    dispatchHttp({ type: 'send', identifier: reqIdentifer });

    try {
      const headers: { Accept: string; Authorization?: string; 'Content-Type'?: string } = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const res: AxiosResponse<ResData> = await axios({
        url,
        method,
        data: body,
        headers,
      });

      dispatchHttp({ type: 'response', resData: res.data });
    } catch (err) {
      dispatchHttp({
        type: 'error',
        message: err?.response?.data?.message ?? err.message,
        status: err?.response?.data?.statusCode ?? err.statusCode,
      });
    }
  }, []);

  return {
    isLoading: httpState.loading,
    data: httpState.data,
    error: httpState.error,
    sendRequest,
    reqIdentifier: httpState.identifier,
    clear,
  };
};

export default useHttp;
