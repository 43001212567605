import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormType } from './types';
import { Input } from '../../UI/Input';
import { SwitchFormButton } from './SwitchFormButton';
import { AuthForm } from './AuthForm';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { postLogin, postRegister } from '../adminSlice';
import { useQuery } from '../../JerseyConfig/hooks/query';

const StyledAdmin = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--color-light);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const StyledAdminFormHeading = styled.h2`
  text-align: center;
  color: var(--color-primary);
  font-size: 1.875rem;
  margin-top:1em;
`;

const StyledAdminFormAgreement = styled.i`
  text-align: center;
  color: var(--color-primary);
  font-size: 0.85rem;
`;

const StyledInput = styled(Input)`
  width: 100%;
  & input {
    background-color: var(--color-light-darken);
  }
`;

const StyledForgottenPasswordLink = styled(Link)`
  color: var(--color-medium);
  margin-top: 2rem;
  font-weight: bold;
`;

type RegisterFormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

export const Auth = () => {
  const { t } = useTranslation(['admin', 'commons']);

  const [formType, setFormType] = useState<FormType>('login');

  const dispatch = useAppDispatch();

  const query = useQuery();

  const projectPathFromUrl = query.get('projectPath');

  const {
    register: registerLoginF,
    handleSubmit: handleSubmitLoginF,
    errors: errorsLoginF,
    reset: resetLoginF,
  } = useForm<RegisterFormData>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email(t('commons:errorMessages.emailRequiredErr'))
          .required(t('commons:errorMessages.emailRequiredErr')),
        password: yup
          .string()
          .test('length', t('admin:auth.passwordLengthErr'), (val) => (val ? val.length >= 8 : false))
          .required(t('admin:auth.passwordRequiredErr')),
      })
    ),
  });

  const {
    register: registerRegF,
    handleSubmit: handleSubmitRegF,
    errors: errorsRegF,
    reset: resetRegF,
  } = useForm<RegisterFormData>({
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required(t('commons:errorMessages.firstNameRequiredErr')),
        lastName: yup.string().required(t('commons:errorMessages.lastNameRequiredErr')),
        email: yup
          .string()
          .email(t('commons:errorMessages.emailNotValidErr'))
          .required(t('commons:errorMessages.emailRequiredErr')),
        password: yup
          .string()
          .test('length', t('admin:auth.passwordLengthErr'), (val) => (val ? val.length >= 8 : false))
          .required(t('admin:auth.passwordRequiredErr')),
      })
    ),
  });

  const onLoginSubmit = handleSubmitLoginF((data) => {
    /* dispatch(login({ email: data.email, password: data.password })); */
    try {
      dispatch(
        postLogin({ data: { email: data.email, password: data.password, path: projectPathFromUrl ?? undefined } })
      );
    } catch (error) {
      console.log("error");
    }
  });

  const onRegisterSubmit = handleSubmitRegF((data) => {
    dispatch(
      postRegister({
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          path: projectPathFromUrl ?? undefined,
        },
      })
    );
  });

  const handleFormSwitchClick = (type: FormType) => {
    setFormType(type);
    resetLoginF();
    resetRegF();
  };

  let authForm;
  let switchButton;

  if (formType === 'login') {
    authForm = (
      <>
        <StyledAdminFormHeading>{t('admin:auth.login')}</StyledAdminFormHeading>
        <AuthForm onSubmit={onLoginSubmit} submitButtonText={t('admin:auth.loginBtn')}>
          <StyledInput
            placeholder={t('admin:auth.email')}
            name="email"
            register={registerLoginF}
            errors={errorsLoginF}
            label={t('admin:auth.email')}
          />
          <StyledInput
            placeholder={t('admin:auth.password')}
            name="password"
            register={registerLoginF}
            errors={errorsLoginF}
            type="password"
            label={t('admin:auth.password')}
          />
        </AuthForm>
      </>
    );
    switchButton = (
      <SwitchFormButton switchToType="registration" onClick={handleFormSwitchClick}>
        {t('admin:auth.switchToReg')}
      </SwitchFormButton>
    );
  } else {
    authForm = (
      <>
        <StyledAdminFormHeading>{t('admin:auth.registration')}</StyledAdminFormHeading>
        <AuthForm onSubmit={onRegisterSubmit} submitButtonText={t('admin:auth.regBtn')}>
          <StyledInput
            placeholder={t('admin:auth.firstName')}
            name="firstName"
            register={registerRegF}
            errors={errorsRegF}
            label={t('admin:auth.firstName')}
          />
          <StyledInput
            placeholder={t('admin:auth.lastName')}
            name="lastName"
            register={registerRegF}
            errors={errorsRegF}
            label={t('admin:auth.lastName')}
          />
          <StyledInput
            placeholder={t('admin:auth.email')}
            name="email"
            register={registerRegF}
            errors={errorsRegF}
            label={t('admin:auth.email')}
          />
          <StyledInput
            placeholder={t('admin:auth.password')}
            name="password"
            register={registerRegF}
            errors={errorsRegF}
            type="password"
            label={t('admin:auth.password')}
          />
        </AuthForm>
        <StyledAdminFormAgreement>{t('admin:auth.agreement')}</StyledAdminFormAgreement>
      </>
    );
    switchButton = (
      <SwitchFormButton switchToType="login" onClick={handleFormSwitchClick}>
        {t('admin:auth.switchToLogin')}
      </SwitchFormButton>
    );
  }

  return (
    <StyledAdmin>
      {authForm}
      {switchButton}
      <StyledForgottenPasswordLink to="/reset-password">Zapomněli jste heslo?</StyledForgottenPasswordLink>
    </StyledAdmin>
  );
};
