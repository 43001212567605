import { FC, InputHTMLAttributes } from 'react';

import styled from 'styled-components';

const StyledInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type RequiredType = {
  isRequired: boolean;
};

const StyledLabel = styled.label<RequiredType>`
  align-self: flex-start;

  &:after {
    content: ${({ isRequired }) => (isRequired ? `'*'` : `''`)};
    color: var(--color-danger);
  }
`;

type StyledInputType = {
  error: any;
};

const StyledInput = styled.input<StyledInputType>`
  flex: 1;
  outline: none;
  border: ${({ error }) => (error ? `1px solid var(--color-danger);` : `1px solid var(--color-primary);`)};
  border-radius: var(--radius);
  /* padding: 0.8rem; */
  padding: 0.5rem;

  font-size: var(--font-size-medium);

  &:focus {
    border: ${({ error }) => (error ? `1px solid var(--color-danger);` : `1px solid var(--color-primary);`)};
  }

  &::placeholder {
    color: var(--color-medium);
  }
`;

const StyledErrorMessage = styled.span`
  color: var(--color-danger);
  text-align: left;
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder: string;
  register: any;
  name: string;
  errors: any;
  isActive?: boolean;
  maxLength?: number;
  max?: number | string;
  min?: number | string;
  className?: string;
  defaultValue?: string | number;
  size?: number;
  showErrMessage?: boolean;
  required?: boolean;
}

export const Input: FC<Props> = ({
  label,
  placeholder,
  register,
  name,
  errors,
  isActive = true,
  maxLength,
  max,
  min,
  className,
  defaultValue,
  type = 'text',
  showErrMessage = true,
  required = false,
}) => {
  let errMessage = null;

  if (errors && errors[name] && errors[name].message) {
    errMessage = <StyledErrorMessage>{errors[name] && errors[name].message}</StyledErrorMessage>;
  }

  let labelContent = null;

  if (label) {
    labelContent = <StyledLabel isRequired={required}>{label}</StyledLabel>;
  }

  return (
    <StyledInputWrapper className={className}>
      {labelContent}
      <StyledInput
        placeholder={placeholder}
        name={name}
        ref={register}
        error={errors && errors[name]}
        disabled={!isActive}
        maxLength={maxLength}
        max={max}
        min={min}
        defaultValue={defaultValue}
        type={type}
      />
      {showErrMessage && errMessage}
    </StyledInputWrapper>
  );
};
