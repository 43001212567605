import { createAction, createSlice } from '@reduxjs/toolkit';
import { fetchProjectByPath } from '../sharedActions';
import { patchAreSocksIncluded } from '../AddonsConfig/addonsSlice';
import { patchSuperjerseyLogoIsActive, patchTextIsActive } from '../TextConfig/textConfigSlice';
import { postLibraryLogo, removeLibraryLogo } from '../LogoConfig/logoConfigSlice';

const resetState = createAction<null>('jerseyConfig/resetState');

type PriceInfoState = {
  status: 'idle' | 'loading' | 'error';
  jerseyPriceSum: {
    groupJersey: number;
    singleJersey: number;
  };
  singleJersey?: number;
  groupJersey?: number;
  logoSuperjerseys?: number;
  sleevesNumber?: number;
  socks?: number;
  appliedExtras: { [key: string]: number };
};

const initialState: PriceInfoState = {
  status: 'idle',
  appliedExtras: {},
  jerseyPriceSum: {
    groupJersey: 0,
    singleJersey: 0,
  },
};

const sliceName = 'priceSlice';

export const priceInfoSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    changeJerseyPriceSum(state) {
      if (state.singleJersey && state.groupJersey) {
        state.jerseyPriceSum.singleJersey = Object.values(state.appliedExtras).reduce(
          (prevVal, currVal) => prevVal + currVal,
          state.singleJersey
        );
        state.jerseyPriceSum.groupJersey = Object.values(state.appliedExtras).reduce(
          (prevVal, currVal) => prevVal + currVal,
          state.groupJersey
        );
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(resetState, (_, _1) => initialState)
      .addCase(fetchProjectByPath.fulfilled, (state, action) => {
        const { projectType, priceConstants } = action.payload;

        if (action.payload.sleevesText.isShown) state.appliedExtras.sleevesNumber = priceConstants.sleevesNumber;
        if (action.payload.isSuperjerseyLogoShown)
          state.appliedExtras.logoSuperjerseys = priceConstants.logoSuperjerseys;

        if (action.payload.logoConfiguration.logo && action.payload.logoConfiguration.logo.price)
          state.appliedExtras.logo = action.payload.logoConfiguration.logo.price;

        if (projectType === 'match') {
          if (action.payload.areSocksIncluded) state.appliedExtras.socks = priceConstants.matchSocks;

          state.singleJersey = priceConstants.matchSingle;
          state.groupJersey = priceConstants.matchGroup;

          state.socks = priceConstants.matchSocks;
        } else {
          if (action.payload.areSocksIncluded) state.appliedExtras.socks = priceConstants.trainingSocks;

          state.singleJersey = priceConstants.trainingSingle;
          state.groupJersey = priceConstants.trainingGroup;
          state.socks = priceConstants.trainingSocks;
        }

        state.logoSuperjerseys = priceConstants.logoSuperjerseys;
        state.sleevesNumber = priceConstants.sleevesNumber;
      })
      .addCase(patchAreSocksIncluded.fulfilled, (state, action) => {
        if (state.socks && state.singleJersey && state.groupJersey) {
          if (action.payload.areSocksIncluded) {
            state.appliedExtras.socks = state.socks;
          } else {
            delete state.appliedExtras.socks;
          }
        }
      })
      .addCase(patchTextIsActive.fulfilled, (state, action) => {
        const { key, isShown } = action.payload;
        if (state.sleevesNumber && state.singleJersey && state.groupJersey) {
          if (key === 'sleevesNumber') {
            if (isShown) {
              state.appliedExtras.sleevesNumber = state.sleevesNumber;
            } else {
              delete state.appliedExtras.sleevesNumber;
            }
          }
        }
      })
      .addCase(patchSuperjerseyLogoIsActive.fulfilled, (state, action) => {
        if (state.logoSuperjerseys && state.singleJersey && state.groupJersey) {
          if (action.payload.isSuperjerseyLogoShown) {
            state.appliedExtras.logoSuperjerseys = state.logoSuperjerseys;
          } else {
            delete state.appliedExtras.logoSuperjerseys;
          }
        }
      })
      .addCase(postLibraryLogo.fulfilled, (state, action) => {
        state.appliedExtras.logo = action.payload.logo.price;
      })
      .addCase(removeLibraryLogo.fulfilled, (state) => {
        delete state.appliedExtras.logo;
      }),
});

export const priceInfoReducer = priceInfoSlice.reducer;

export const { changeJerseyPriceSum } = priceInfoSlice.actions;
