import { createSlice, createAction } from '@reduxjs/toolkit';
import { fetchProjectByPath } from '../sharedActions';
import { patchProject } from '../reusableActions';
import { createAsyncAction, createCustomAsyncThunk } from '../../utils';

const resetState = createAction<null>('jerseyConfig/resetState');

type TeamNameState = {
  status: 'idle' | 'loading' | 'failed';
  teamName: string;
  loadingId?: 'fetch' | 'others';
};

const initialState: TeamNameState = {
  status: 'idle',
  teamName: '',
};

const sliceName = 'teamName';

const asyncActions = {
  createProject: 'createProject',
  patchProjectName: 'patchProjectName',
};

type PatchParams = {
  path: string;
  name: string;
};

export const patchProjectName = patchProject<PatchParams, PatchParams>(
  createAsyncAction(sliceName, asyncActions.patchProjectName)
);

type CreateProjectRes = {
  name: string;
  userId?: number | null;
  configType: string;
  path: string;
};

type CreateProjectParams = {
  data: {
    name: string;
    userId?: number | null;
    configType: string;
  };
};

export const createProject = createCustomAsyncThunk<CreateProjectRes, CreateProjectParams>(
  createAsyncAction(sliceName, asyncActions.createProject),
  {
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/projects`,
  }
);

export const teamNameSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(resetState, (_, _1) => initialState)
      .addCase(createProject.pending, (state) => {
        state.status = 'loading';
        state.loadingId = 'others';
      })
      .addCase(createProject.fulfilled, (state, action) => {
        // when project is successfully created, fetchProjectByPath is called right away
        // these two async operations need to happen in order to allow user to proceed
        // that is why createProject doesn't set status to idle
        state.teamName = action.payload.name;
      })
      .addCase(createProject.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchProjectByPath.pending, (state) => {
        state.status = 'loading';
        state.loadingId = 'fetch';
      })
      .addCase(fetchProjectByPath.fulfilled, (state, action) => {
        state.status = 'idle';
        if (state.teamName) return;
        state.teamName = action.payload.name;
      })
      .addCase(fetchProjectByPath.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(patchProjectName.pending, (state) => {
        state.status = 'loading';
        state.loadingId = 'others';
      })
      .addCase(patchProjectName.fulfilled, (state, action) => {
        state.status = 'idle';
        state.teamName = action.payload.name;
      })
      .addCase(patchProjectName.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const teamNameReducer = teamNameSlice.reducer;
