import { decode } from 'jsonwebtoken';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PatternRes } from './JerseyConfig/types';

export const breakpoint = {
  tablet: 768,
};

export const configSections = ['teamName', 'colors', 'patterns', 'text', 'logo', 'addons'];

export const authTokenName = 'dressToken';
export const refreshTokenName = 'dressRefreshToken';

// export const createUrlToStaticFile = (staticFileStorageName: string) =>
//   `${process.env.REACT_APP_STORAGE_URL}/${staticFileStorageName}`;

export const createUrlToStaticFile = ({
  id,
  folderName,
  ext = '.png',
}: {
  id: number;
  folderName: string;
  ext: string;
}) => `${process.env.REACT_APP_STORAGE_URL}/${folderName}/${id}${ext}`;

export const isJWTExpired = (token: string): boolean => {
  let isExpired = true;

  const decodedToken: any = decode(token, { complete: true });

  if (decodedToken === null) return isExpired;
  if (!decodedToken.payload.exp) return isExpired;

  const dateNow = new Date();

  if (decodedToken.payload.exp * 1000 > dateNow.getTime()) isExpired = false;

  return isExpired;
};

export const handleJWTValidity = (tokenName: string) => {
  const token = localStorage.getItem(tokenName);

  if (!token) return null;

  if (isJWTExpired(token)) {
    localStorage.removeItem(tokenName);
    return null;
  }

  return token;
};

// FOR REDUX SLICES
type ThunkAxiosParams<U> = {
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  url: string | ((params: U) => string);
  headers?: {
    contentType?: string;
    [key: string]: any;
  };
};

type AsyncThunkParams = {
  data?: Record<string, unknown>;
  [key: string]: unknown;
};

export const createCustomAsyncThunk = <T extends unknown, U extends AsyncThunkParams>(
  actionName: string,
  axiosParams: ThunkAxiosParams<U>
) =>
  createAsyncThunk<T, U, { rejectValue: any }>(actionName, async (params: U, { rejectWithValue }) => {
    const { method, url, headers } = axiosParams;

    const { data, ...extras } = params;

    const axiosUrl = typeof url === 'string' ? url : url({ ...params });

    try {
      const res = await axios({
        method,
        url: axiosUrl,
        data,
        headers: {
          'Content-Type': headers && headers.contentType ? headers.contentType : 'application/json',
          ...headers,
        },
      });

      if (Array.isArray(res.data.data)) return { ...res.data, ...extras } as T;

      return { ...res.data.data, ...extras } as T;
    } catch (err) {
      let errors = err.response.data;

      if (!errors) errors = {};

      return rejectWithValue({
        data: errors,
        code: err.response.status,
      });
    }
  });

export const textStrokeWidth = 20;

export const createAsyncAction = (sliceName: string, actionName: string) => `${sliceName}/${actionName}`;
export const fetchPatternById = async (patternId: number) =>
  axios.get<{ data: PatternRes }>(`${process.env.REACT_APP_API_URL}/patterns/${patternId}`);

export const sizes = [
  {
    id: 'XXS',
    name: 'XXS',
  },
  {
    id: 'XS',
    name: 'XS',
  },
  {
    id: 'S',
    name: 'S',
  },
  {
    id: 'M',
    name: 'M',
  },
  {
    id: 'L',
    name: 'L',
  },
  {
    id: 'XL',
    name: 'XL',
  },
  {
    id: 'XXL',
    name: 'XXL',
  },
  {
    id: 'GL',
    name: 'Brankář',
  },
];

export const getApiErrorMessage = (errorMessage: string, t: any) => {
  const translationKey = `commons:apiErrorMessages.${errorMessage}`;
  return t(translationKey as any);
};

export const setBackNameFontSize = (
  text: string,
  backNameCharsPerLine: number,
  backNameTextBaseSize: number
): number => {
  const textLength = text.length;
  // const charsPerLine = backNameCharsPerLine;
  const newEmSize = backNameCharsPerLine / textLength;
  const textBaseSize = backNameTextBaseSize;

  if (newEmSize < 1) {
    return newEmSize * textBaseSize;
  }
  return textBaseSize;
};
