import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';
import { Address, Addresses } from '../../types';
import { createAsyncAction, createCustomAsyncThunk } from '../../../utils';
import { postSignout } from '../../adminSlice';

const sliceName = 'accountSettings';

const asyncActions = {
  changeUserPassword: 'changeUserPassword',
};

type AccountSettingsState = {
  status: 'idle' | 'loading' | 'failed';
  errorMessages: { [key: string]: string[] };
  authToken: string;

  firstName: string;
  lastName: string;
  email: string;
  addresses: Addresses;
};

const initialState: AccountSettingsState = {
  status: 'idle',
  errorMessages: {},
  authToken: '',

  firstName: '',
  lastName: '',
  email: '',
  addresses: [],
};

type PostUserAddressRes = Address;
type PostUserAddressParams = {
  id: number;
  data: {
    firstName: string | null;
    lastName: string | null;
    street: string | null;
    city: string | null;
    postalCode: string | null;
    phone: string | null;
    companyName: string | null;
    ico: string | null;
    dic: string | null;
    type: string;
  };
};

// LEGACY ERROR TYPE
// type PostUserAddressError = { errMessages: { [key: string]: string[] }; code: number };

export const postUserAddress = createCustomAsyncThunk<PostUserAddressRes, PostUserAddressParams>(
  'accountSettings/postUserAddress',
  {
    method: 'POST',
    url: ({ id }) => `${process.env.REACT_APP_API_URL}/user/${id}/addresses`,
  }
);

type PatchUserAddressRes = Address;
type PatchUserAddressParams = {
  userId: number;
  addressId: number;
  data: {
    firstName: string;
    lastName: string;
    street: string | null;
    city: string | null;
    postalCode: string | null;
    phone: string | null;
    companyName: string | null;
    ico: string | null;
    dic: string | null;
    type: string;
  };
};

// LEGACY ERROR TYPE
// type PatchUserAddressError = { errMessages: { [key: string]: string[] }; code: number };

export const patchUserAddress = createCustomAsyncThunk<PatchUserAddressRes, PatchUserAddressParams>(
  'accountSettings/patchUserAddress',
  {
    method: 'PATCH',
    url: ({ userId, addressId }) => `${process.env.REACT_APP_API_URL}/user/${userId}/addresses/${addressId}`,
  }
);

type GetUserRes = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string | null;
};
type GetUserParams = { id: number };

export const getUser = createCustomAsyncThunk<GetUserRes, GetUserParams>('accountSettings/getUser', {
  method: 'GET',
  url: ({ id }) => `${process.env.REACT_APP_API_URL}/user/${id}`,
});

type GetUserAddressesRes = { data: Addresses };
type GetUserAddressesParams = { id: number };

export const getUserAddresses = createCustomAsyncThunk<GetUserAddressesRes, GetUserAddressesParams>(
  'accountSettings/getUserAddresses',
  {
    method: 'GET',
    url: ({ id }) => `${process.env.REACT_APP_API_URL}/user/${id}/addresses`,
  }
);

type PatchUserInfoRes = {
  email: string;
  firstName: string;
  lastName: string;
};
type PatchUserInfoParams = {
  id: number;
  data: {
    firstName: string;
    lastName: string;
    email: string;
  };
};
// LEGACY ERROR TYPE
// type PatchUserInfoError = { errMessages: { [key: string]: string[] }; code: number };

export const patchUserInfo = createCustomAsyncThunk<PatchUserInfoRes, PatchUserInfoParams>('admin/patchUserInfo', {
  method: 'PATCH',
  url: ({ id }) => `${process.env.REACT_APP_API_URL}/user/${id}`,
});

// PATCH
type PatchUserPasswordRes = {};
type PatchUserPasswordArgs = { userId: number; data: { passwordOld: string; password: string } };

export const changeUserPassword = createCustomAsyncThunk<PatchUserPasswordRes, PatchUserPasswordArgs>(
  createAsyncAction(sliceName, asyncActions.changeUserPassword),
  {
    method: 'PATCH',
    url: ({ userId }) => `${process.env.REACT_APP_API_URL}/user/${userId}/password-change`,
  }
);

export const accountSettingsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearError(state, _) {
      state.errorMessages = {};
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.email = action.payload.email;
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
      })
      .addCase(getUserAddresses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserAddresses.fulfilled, (state, action) => {
        state.status = 'idle';
        state.addresses = action.payload.data;
      })
      .addCase(postUserAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postUserAddress.fulfilled, (state, action) => {
        state.status = 'idle';
        state.addresses.push(action.payload);
      })
      .addCase(postUserAddress.rejected, (state, action) => {
        state.status = 'failed';

        if (!action.payload) return;

        state.errorMessages = action.payload.errMessages;
      })
      .addCase(patchUserAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchUserAddress.fulfilled, (state, action) => {
        state.status = 'idle';
        state.addresses = state.addresses.map((address) =>
          address.id === action.payload.addressId ? action.payload : address
        );
      })
      .addCase(patchUserAddress.rejected, (state, action) => {
        state.status = 'failed';

        if (!action.payload) return;

        state.errorMessages = action.payload.errMessages;
      })
      .addCase(patchUserInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchUserInfo.fulfilled, (state, action) => {
        state.status = 'idle';
        state.email = action.payload.email;
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
      })
      .addCase(patchUserInfo.rejected, (state, action) => {
        state.status = 'failed';

        if (!action.payload) return;

        state.errorMessages = action.payload.errMessages;
      })

      .addCase(changeUserPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changeUserPassword.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(changeUserPassword.rejected, (state) => {
        state.status = 'failed';

        // TODO state.errorTranslationKey = 'invalidPassword'
      })
      .addCase(postSignout.fulfilled, () => initialState);
  },
});

export const findUserAddresses = (state: RootState) => {
  const shippingAddress = state.accountSettings.addresses.find((address) => address.type === 'shipping');
  const billingAddress = state.accountSettings.addresses.find((address) => address.type === 'billing');

  return { shippingAddress, billingAddress };
};

export const selectIsAuth = (state: RootState) => !!state.admin.authToken;
export const { clearError } = accountSettingsSlice.actions;
export const accountSettingsReducer = accountSettingsSlice.reducer;
