import { FC } from 'react';

import styled from 'styled-components';

import { RoundedButton } from '../../UI/RoundedButton';

const StyledForm = styled.form`
  padding: 1rem;
  display: grid;
  grid-row-gap: 1rem;
  max-width: 400px;
  width: 100%;
`;

const StyledSubmitButton = styled(RoundedButton)`
  align-self: center;
  justify-self: center;
`;

type Props = {
  onSubmit: any;
  submitButtonText: string;
};

export const AuthForm: FC<Props> = ({
  onSubmit,
  submitButtonText,
  children,
}) => (
    <StyledForm onSubmit={onSubmit}>
      {children}
      <StyledSubmitButton fill="full" color="success">
        {submitButtonText}
      </StyledSubmitButton>
    </StyledForm>
  );
