import { createSlice, PayloadAction, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store/store';
import { FrontLogo } from '../../../types';
import { createAsyncAction, createCustomAsyncThunk, createUrlToStaticFile } from '../../utils';
import { StaticFile } from '../types';
import { ActiveLogo, LogoCategories, Logos } from './types';
import { fetchProjectByPath } from '../sharedActions';
import { createFrontLogoDefaultObject } from '../../../jerseyLogicCommons/jerseyFunctions';

const resetState = createAction<null>('jerseyConfig/resetState');

const sliceName = 'logoConfig';
const asyncActions = {
  fetchLibraryLogos: 'fetchLibraryLogos',
  fetchSelectedLogo: 'fetchSelectedLogo',
  fetchLogoCategories: 'fetchLogoCategories',
  patchLibraryLogo: 'patchLibraryLogo',
  patchLibraryLogoColor: 'patchLibraryLogoColor',
  removeLibraryLogo: 'removeLibraryLogo',
  patchPosY: 'patchPosY',
  patchLogoSize: 'patchLogoSize',
  resetLibraryLogoColors: 'resetLibraryLogoColors',
  patchRequestProfiLogo: 'patchRequestProfiLogo',
  postUserLogo: 'postUserLogo',
  deleteUserLogo: 'deleteUserLogo',
};

type LogoConfigState = {
  status: 'loading' | 'idle' | 'failed';
  logoFileError: string;
  frontLogo: FrontLogo;
  shouldersLogo: {
    id: null;
    url: string;
    data: string | ArrayBuffer;
    originalWidth: number | null;
    originalHeight: number | null;
    fileSize: number | null;
    fileName: string;
    extension: string;
  };
  libraryLogos: Logos;
  logoCategories: LogoCategories;
  activeLogoType: ActiveLogo;
  logoConfiguration: {
    id: null | number;
  };
  /*  logoSlidersMinMax: {
    viewboxWidth: number;
    viewboxHeight: number;
    posYMax: number | null;
    posYMin: number | null;
  }; */
};

const initialState: LogoConfigState = {
  status: 'idle',
  logoFileError: '',
  frontLogo: createFrontLogoDefaultObject(1738.04, 2160),
  shouldersLogo: {
    id: null,
    url: '',
    data: '',
    originalWidth: null,
    originalHeight: null,
    fileSize: null,
    fileName: '',
    extension: '',
  },
  activeLogoType: 'frontLogo',
  /* logoSlidersMinMax: {
    viewboxWidth: 1738.04,
    viewboxHeight: 2160,
    posYMax: null,
    posYMin: null,
  }, */
  logoConfiguration: {
    id: null,
  },
  libraryLogos: [],
  logoCategories: [],
};

type FrontLogoActionPayload = {
  url: string;
  data: string | ArrayBuffer;
  isDefault: boolean;
  isUsersOwnLogo: boolean;
  width?: number;
  height?: number;
  fileSize?: number;
  fileName?: string;
  extension?: string;
};

type LogoCategory = { id: number; nameCs: string; nameEn: string };

type LogoCategoriesRes = {
  id: number;
  nameCs: string;
  nameEn: string;
}[];

type LogosRes = {
  id: number;
  colorCount: number;
  price: number;
  staticFileId: number;
  logoCategoryId: number;
  isPro: 0 | 1;
  name: string;
  staticFile: StaticFile;
  logoCategory: LogoCategory;
}[];

type LibraryLogosContentReturn = {
  logos: LogosRes;
  logoCategories: LogoCategoriesRes;
  appLang: string;
};

export const fetchLibraryLogosContent = createAsyncThunk(
  `${sliceName}/${asyncActions.fetchLibraryLogos}`,
  async (appLang: string): Promise<LibraryLogosContentReturn> => {
    const logosRes = await axios.get<{ data: LogosRes }>(`${process.env.REACT_APP_API_URL}/logos`);

    const logosCategoriesRes = await axios.get<{ data: LogoCategoriesRes }>(
      `${process.env.REACT_APP_API_URL}/logo-categories`
    );
    /* return logosCategoriesRes.data; */

    return {
      logos: logosRes.data.data,
      logoCategories: logosCategoriesRes.data.data,
      appLang,
    };
  }
);

type LogoPartColor = {
  id: number;
  value: string;
  nameCs: string;
  nameEn: string;
  createdAt: Date;
  updatedAt: Date;
};

// THUNKS

type LibraryLogo = {
  id: number;
  colorCount: number;
  price: number;
  staticFileId: number;
  logoCategoryId: number;
  svg: string;
  isPro: 0 | 1;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  staticFile: StaticFile;
};

export type LogoPart = {
  colorId: number;
  logoId: number;
  order: number;
  id: number;
  color: LogoPartColor;
  logo: LibraryLogo;
};

type SelectedLogoRes = {
  id: number;
  colorCount: number;
  price: number;
  staticFileId: number;
  logoCategoryId: number;
  svg: string;
  isPro: 0 | 1;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  iconStaticFile: StaticFile;
  logoCategory: LogoCategory;
  logoPartDefault: LogoPart[];
};

export const fetchSelectedLogo = createAsyncThunk(
  createAsyncAction(sliceName, asyncActions.fetchSelectedLogo),
  async (logoId: number): Promise<SelectedLogoRes> => {
    const url = `${process.env.REACT_APP_API_URL}/logos/${logoId}`;
    const logoRes = await axios.get<{ data: SelectedLogoRes }>(url);
    return logoRes.data.data;
  }
);

type PatchLibraryLogoParams = {
  data: {
    logoId: number;
    configId: number;
  };
};

type PatchLibraryLogoRes = {
  logoId: number;
  configId: number;
  logoParts: LogoPart[];
  logo: LibraryLogo;
};

export const postLibraryLogo = createCustomAsyncThunk<PatchLibraryLogoRes, PatchLibraryLogoParams>(
  createAsyncAction(sliceName, asyncActions.patchLibraryLogo),
  {
    method: 'POST',
    url: ({ data }) =>
      `${process.env.REACT_APP_API_URL}/logo-configurations/${data.configId}/library-logo/${data.logoId}`,
  }
);

type RemoveLibraryLogoParams = {
  data: {
    configId: number;
    logoId: number;
  };
  teamName: string;
};

type RemoveLibraryLogoRes = {
  configId: number;
  logoId: null;
  type: string;
  teamName: string;
};

export const removeLibraryLogo = createCustomAsyncThunk<RemoveLibraryLogoRes, RemoveLibraryLogoParams>(
  createAsyncAction(sliceName, asyncActions.removeLibraryLogo),
  {
    method: 'PATCH',
    url: ({ data }) =>
      `${process.env.REACT_APP_API_URL}/logo-configurations/${data.configId}/library-logo/${data.logoId}`,
  }
);

type PatchLibraryLogoColorParams = {
  data: {
    configId: number;
    logoPartId: number;
    colorId: number;
  };
};

type PatchLibraryLogoColorRes = {
  logoPartId: number;
  colorId: number;
  configId: number;
  order: number;
  color: LogoPartColor;
};

export const patchLibraryLogoColor = createCustomAsyncThunk<PatchLibraryLogoColorRes, PatchLibraryLogoColorParams>(
  createAsyncAction(sliceName, asyncActions.patchLibraryLogoColor),
  {
    method: 'PATCH',
    url: ({ data }) =>
      `${process.env.REACT_APP_API_URL}/logo-configurations/${data.configId}/logo-parts/${data.configId}`,
  }
);

type PatchPosYParams = {
  data: {
    configId: number;
    positionY: number;
  };
};

type PatchPosYRes = {
  configId: number;
  positionY: number;
};

export const patchPosY = createCustomAsyncThunk<PatchPosYRes, PatchPosYParams>(
  createAsyncAction(sliceName, asyncActions.patchPosY),
  {
    method: 'PATCH',
    url: ({ data }) => `${process.env.REACT_APP_API_URL}/logo-configurations/${data.configId}`,
  }
);

type PatchLogoSizeParams = {
  data: {
    configId: number;
    size: number;
  };
};

type PatchLogoSizeRes = {
  configId: number;
  size: number;
};

export const patchLogoSize = createCustomAsyncThunk<PatchLogoSizeRes, PatchLogoSizeParams>(
  createAsyncAction(sliceName, asyncActions.patchLogoSize),
  {
    method: 'PATCH',
    url: ({ data }) => `${process.env.REACT_APP_API_URL}/logo-configurations/${data.configId}`,
  }
);

type ResetLibraryLogoColorsParams = {
  data: {
    logoId: number;
  };
  configId: number;
};

type ResetLibraryLogoColorsRes = {
  configId: number;
  logoParts: LogoPart[];
};

export const resetLibraryLogoColors = createCustomAsyncThunk<ResetLibraryLogoColorsRes, ResetLibraryLogoColorsParams>(
  createAsyncAction(sliceName, asyncActions.resetLibraryLogoColors),
  {
    method: 'PATCH',
    url: ({ configId }) => `${process.env.REACT_APP_API_URL}/logo-configurations/${configId}/logo-parts`,
  }
);

type PatchRequestProfiLogoParams = {
  data: {
    type: 'proRequest' | 'default';
  };
  teamName: string;
  configId: number;
  clientSideType: ActiveLogo;
};

type PatchRequestProfiLogoRes = {
  isProRequested: boolean;
  configId: number;
  type: 'proRequest' | 'default';
  clientSideType: ActiveLogo;
  teamName: string;
};

export const patchRequestProfiLogo = createCustomAsyncThunk<PatchRequestProfiLogoRes, PatchRequestProfiLogoParams>(
  createAsyncAction(sliceName, asyncActions.patchRequestProfiLogo),
  {
    method: 'PATCH',
    url: ({ configId }) => `${process.env.REACT_APP_API_URL}/logo-configurations/${configId}`,
  }
);

type PostUserLogoParams = {
  configId: number;
  path: string;
  data: any;
  type: 'front' | 'shoulders';
};

type PostUserLogoRes = {
  configId: number;
  path: string;
  type: 'front' | 'shoulders';
  staticFile: {
    extension: string;
    folderName: string;
    id: number;
    isDeleted: boolean;
    name: string;
    width: number;
    height: number;
    size: number;
  };
};

export const postUserLogo = createCustomAsyncThunk<PostUserLogoRes, PostUserLogoParams>(
  createAsyncAction(sliceName, asyncActions.postUserLogo),
  {
    method: 'POST',
    url: ({ configId, path, type }) =>
      `${process.env.REACT_APP_API_URL}/logo-configurations/${configId}/static-logo?path=${path}&type=${type}`,
    headers: {
      contentType: 'multipart/form-data',
    }
  }
);

//   deleteUserLogo({
//         data: { configId: logoConfigurationId, path: projectPath, type: 'front' },
//         firstLetter: teamName[0],
//       })

type DeleteUserLogoParams = {
  configId: number;
  type: 'front' | 'shoulders';
  firstLetter: string;
  logoFileName: string;
};

type DeleteUserLogoRes = {
  configId: number;
  data: any;
  type: 'front' | 'shoulders';
  firstLetter: string;
  logoFileName: string;
};

export const deleteUserLogo = createCustomAsyncThunk<DeleteUserLogoRes, DeleteUserLogoParams>(
  createAsyncAction(sliceName, asyncActions.deleteUserLogo),
  {
    method: 'DELETE',
    url: ({ configId, type, logoFileName }) =>
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_API_URL}/logo-configurations/${configId}/static-logo?logoFileName=${logoFileName}&type=${type}`,
  }
);

// END OF THUNKS

// HELPERS
const setDefaultLogo = (state: LogoConfigState, teamName: string) => {
  const firstLetter = teamName.charAt(0);
  state.frontLogo.url = '';
  state.frontLogo.data = firstLetter;
  state.frontLogo.isDefault = true;
  state.frontLogo.isUsersOwnLogo = false;
};

const setLogoColors = (state: LogoConfigState, logoParts: LogoPart[]) => {
  state.frontLogo.colors = logoParts.map((c) => ({
    colorNumber: c.order,
    value: c.color.value,
    colorId: c.colorId,
    logoPartId: c.id,
  }));
};

// END OF HELPERS

export const logoConfigSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    changeFrontLogo(state, action: PayloadAction<FrontLogoActionPayload>) {
      state.frontLogo.data = action.payload.data;
      state.frontLogo.url = action.payload.url;
      /* state.frontLogo.category = action.payload.category; */
      state.frontLogo.isDefault = action.payload.isDefault;
      state.frontLogo.isUsersOwnLogo = action.payload.isUsersOwnLogo;

      // Optionals - only when logo is user's own image
      const { width, height, fileSize, fileName, extension } = action.payload;
      state.frontLogo.originalWidth = width === undefined ? null : width;
      state.frontLogo.originalHeight = height === undefined ? null : height;
      state.frontLogo.fileSize = fileSize === undefined ? null : fileSize;
      state.frontLogo.fileName = fileName === undefined ? '' : fileName;
      state.frontLogo.extension = extension === undefined ? '' : extension;
      // state.frontLogo.posY = 2160;
    },
    changeIsOutsideGivenSpace(state, action: PayloadAction<boolean>) {
      state.frontLogo.isOutsideGivenSpace = action.payload;
    },
    changeFrontLogoPosY(state, action: PayloadAction<number>) {
      state.frontLogo.posY = action.payload;
    },
    changeFrontLogoImageSize(state, action: PayloadAction<number>) {
      state.frontLogo.size = action.payload;
    },
    changeSelectedLibraryLogo(state, action: PayloadAction<number>) {
      state.libraryLogos = state.libraryLogos.map((item) => {
        if (item.id === action.payload) return { ...item, isSelected: true };
        return { ...item, isSelected: false };
      });
    },
    changeActiveLibraryCategory(state, action: PayloadAction<number>) {
      state.logoCategories = state.logoCategories.map((cat) => {
        if (cat.id === action.payload) return { ...cat, isActive: true };
        return { ...cat, isActive: false };
      });
    },
    changeActiveLogoType(state, action: PayloadAction<ActiveLogo>) {
      state.activeLogoType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetState, (_, _1) => initialState)
      .addCase(fetchLibraryLogosContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLibraryLogosContent.fulfilled, (state, action) => {
        state.status = 'idle';

        const { logos, logoCategories, appLang } = action.payload;

        state.libraryLogos = logos.map((logo) => ({
          id: logo.id,
          isPro: !!logo.isPro,
          price: logo.price,
          categoryId: logo.logoCategory.id, // logo.logoCategory.id,
          url: createUrlToStaticFile({
            id: logo.staticFile.id,
            folderName: logo.staticFile.folderName,
            ext: logo.staticFile.extension,
          }),
          isSelected: false,
          content: '',
          logoCategory: {
            id: logo.logoCategory.id,
            nameCs: logo.logoCategory.nameCs,
            nameEn: logo.logoCategory.nameEn,
          },
        }));

        const nameKey: keyof LogoCategory = `name${
          appLang.charAt(0).toUpperCase() + appLang.slice(1)
        }` as keyof LogoCategory;

        state.logoCategories = [
          { name: 'Všechna loga', id: null, isActive: true },
          ...logoCategories.map((cat) => ({
            name: cat[nameKey] as string,
            id: cat.id,
            isActive: false,
          })),
        ];
      })
      .addCase(fetchSelectedLogo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSelectedLogo.fulfilled, (state, action) => {
        state.status = 'idle';
        const logo = action.payload;
        state.frontLogo.id = logo.id;
        state.frontLogo.data = logo.svg;
        state.frontLogo.isUsersOwnLogo = false;
        state.frontLogo.isDefault = false;
        // state.frontLogo.posY = 2160;
        state.frontLogo.url = createUrlToStaticFile({
          id: logo.iconStaticFile.id,
          folderName: logo.iconStaticFile.folderName,
          ext: logo.iconStaticFile.extension,
        });
        // state.frontLogo.colors = defColors;
        setLogoColors(state as LogoConfigState, logo.logoPartDefault);
        state.frontLogo.colorCount = logo.colorCount;
      })
      .addCase(fetchProjectByPath.fulfilled, (state, action) => {
        state.status = 'idle';
        const { logoConfiguration } = action.payload;
        state.logoConfiguration.id = action.payload.logoConfiguration.id;

        if (logoConfiguration.shouldersStaticFile) {
          // eslint-disable-next-line
          state.shouldersLogo.url = `${process.env.REACT_APP_USER_LOGOS_PATH}/${logoConfiguration.shouldersStaticFile.name}`;
          state.shouldersLogo.extension = logoConfiguration.shouldersStaticFile.extension;
          state.shouldersLogo.originalWidth = logoConfiguration.shouldersStaticFile.width;
          state.shouldersLogo.originalHeight = logoConfiguration.shouldersStaticFile.height;
          state.shouldersLogo.fileName = logoConfiguration.shouldersStaticFile.name;
          state.shouldersLogo.fileSize = logoConfiguration.shouldersStaticFile.size / 1000;
        }

        switch (logoConfiguration.type) {
          case 'default':
            setDefaultLogo(state as LogoConfigState, action.payload.name);
            break;
          case 'pattern':
            state.frontLogo.id = logoConfiguration.logo.id;
            state.frontLogo.data = logoConfiguration.logo.svg;
            state.frontLogo.isUsersOwnLogo = false;
            state.frontLogo.isDefault = false;
            // state.frontLogo.posY = 2160;
            state.frontLogo.url = createUrlToStaticFile({
              id: logoConfiguration.logo.staticFile.id,
              folderName: logoConfiguration.logo.staticFile.folderName,
              ext: logoConfiguration.logo.staticFile.extension,
            });
            // state.frontLogo.colors = colors;
            setLogoColors(state as LogoConfigState, action.payload.logoConfiguration.logoParts);
            state.frontLogo.colorCount = logoConfiguration.logo.colorCount;
            break;
          case 'upload': {
            const { frontStaticFile } = action.payload.logoConfiguration;
            state.frontLogo.url = `${process.env.REACT_APP_USER_LOGOS_PATH}/${frontStaticFile.name}`;
            state.frontLogo.extension = frontStaticFile.extension;
            state.frontLogo.extension = frontStaticFile.extension;
            state.frontLogo.originalWidth = frontStaticFile.width;
            state.frontLogo.originalHeight = frontStaticFile.height;
            state.frontLogo.fileName = frontStaticFile.name;
            state.frontLogo.isUsersOwnLogo = true;
            state.frontLogo.isDefault = false;
            state.frontLogo.fileSize = frontStaticFile.size / 1000;
            break;
          }
          case 'proRequest':
            state.activeLogoType = 'profiLogo';
            break;
          default:
            // eslint-disable-next-line no-console
            console.error('Unknown logo configuration type!');
        }
        state.frontLogo.posY = action.payload.logoConfiguration.positionY;
        state.frontLogo.size = action.payload.logoConfiguration.size;
      })
      .addCase(postLibraryLogo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postLibraryLogo.fulfilled, (state, action) => {
        state.status = 'idle';
        const { logo } = action.payload;
        state.frontLogo.id = logo.id;
        state.frontLogo.data = logo.svg;
        state.frontLogo.isUsersOwnLogo = false;
        state.frontLogo.isDefault = false;
        // state.frontLogo.posY = 2160;
        // state.frontLogo.size = 1738.04;
        state.frontLogo.url = createUrlToStaticFile({
          id: logo.staticFile.id,
          folderName: logo.staticFile.folderName,
          ext: logo.staticFile.extension,
        });
        // state.frontLogo.colors = colors;
        setLogoColors(state as LogoConfigState, action.payload.logoParts);
        state.frontLogo.colorCount = logo.colorCount;
      })
      .addCase(patchLibraryLogoColor.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchLibraryLogoColor.fulfilled, (state, action) => {
        state.status = 'idle';
        state.frontLogo.colors = state.frontLogo.colors.map((color) => {
          if (color.colorNumber === action.payload.order) {
            return {
              ...color,
              value: action.payload.color.value,
              colorId: action.payload.colorId,
            };
          }
          return color;
        });
      })
      .addCase(removeLibraryLogo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeLibraryLogo.fulfilled, (state, action) => {
        state.status = 'idle';
        const { teamName } = action.payload;
        setDefaultLogo(state as LogoConfigState, teamName);
      })
      .addCase(patchPosY.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchPosY.fulfilled, (state, action) => {
        state.status = 'idle';
        state.frontLogo.posY = action.payload.positionY;
      })
      .addCase(patchLogoSize.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchLogoSize.fulfilled, (state, action) => {
        state.status = 'idle';
        state.frontLogo.size = action.payload.size;
      })
      .addCase(resetLibraryLogoColors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetLibraryLogoColors.fulfilled, (state, action) => {
        state.status = 'idle';
        setLogoColors(state as LogoConfigState, action.payload.logoParts);
      })
      .addCase(patchRequestProfiLogo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchRequestProfiLogo.fulfilled, (state, action) => {
        state.status = 'idle';
        state.activeLogoType = action.payload.type === 'proRequest' ? 'profiLogo' : 'frontLogo';
        const { teamName } = action.payload;
        setDefaultLogo(state as LogoConfigState, teamName);
      })
      .addCase(postUserLogo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postUserLogo.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload.type === 'front') {
          // Date now is there as query param, so the browser cache is forced to be ignored and image is refetched
          // otherwise uploaded image does not change, as it always has the same name (image is called by project path)
          // so from the browser stand point it is always the same image
          state.frontLogo.url = `${process.env.REACT_APP_USER_LOGOS_PATH}/${
            action.payload.staticFile.name
          }?${Date.now()}`;
          state.frontLogo.extension = action.payload.staticFile.extension;
          state.frontLogo.originalWidth = action.payload.staticFile.width;
          state.frontLogo.originalHeight = action.payload.staticFile.height;
          state.frontLogo.fileName = action.payload.staticFile.name;
          state.frontLogo.isUsersOwnLogo = true;
          state.frontLogo.isDefault = false;
          state.frontLogo.fileSize = action.payload.staticFile.size / 1000;
        } else if (action.payload.type === 'shoulders') {
          state.shouldersLogo.url = `${process.env.REACT_APP_USER_LOGOS_PATH}/${
            action.payload.staticFile.name
          }?${Date.now()}`;
          state.shouldersLogo.extension = action.payload.staticFile.extension;
          state.shouldersLogo.originalWidth = action.payload.staticFile.width;
          state.shouldersLogo.originalHeight = action.payload.staticFile.height;
          state.shouldersLogo.fileName = action.payload.staticFile.name;
          state.shouldersLogo.fileSize = action.payload.staticFile.size / 1000;
        }
      })
      .addCase(deleteUserLogo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUserLogo.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload.type === 'front') {
          state.frontLogo.url = '';
          state.frontLogo.isDefault = true;
          state.frontLogo.isUsersOwnLogo = false;
          state.frontLogo.data = action.payload.firstLetter;
        } else if (action.payload.type === 'shoulders') {
          state.shouldersLogo.url = '';
          state.shouldersLogo.data = '';
          state.shouldersLogo.originalWidth = null;
          state.shouldersLogo.originalHeight = null;
          state.shouldersLogo.fileSize = null;
          state.shouldersLogo.fileName = '';
          state.shouldersLogo.extension = '';
        }
      });
  },
});

export const selectActiveLogoCategoryId = (state: RootState) => {
  const category = state.logoConfig.logoCategories.find((cat) => cat.isActive);
  return category?.id;
};

export const {
  changeFrontLogo,
  changeFrontLogoPosY,
  changeFrontLogoImageSize,
  changeActiveLibraryCategory,
  changeSelectedLibraryLogo,
  changeIsOutsideGivenSpace,
  changeActiveLogoType,
} = logoConfigSlice.actions;

export const logoConfigReducer = logoConfigSlice.reducer;