import { useEffect, lazy, FC, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { changeDevice } from './appSlice';
import appBg from '../../assets/images/app-bg.png';
import { setAuthToken } from '../Admin/adminSlice';
import { getUser } from '../Admin/Profile/AccountSettings/accountSettingsSlice';
import { handleJWTValidity, refreshTokenName, authTokenName } from '../utils';
import { useAppSelector } from '../hooks/reduxHooks';
import { OrderStatus } from '../Order/OrderStatus';
import { clearErrors, setError } from '../errorsSlice';
import 'react-toastify/dist/ReactToastify.css';
import ErrorToast from '../UI/ErrorToast';
import Header from './components/Header';
import ProjectLoadingScreen from './components/ProjectLoadingScreen';
import { axiosInterceptorRefreshToken, refreshAccessToken } from './axiosInterceptorRefreshToken';
import RequestResetPassword from '../ResetPassword/RequestResetPassword/RequestResetPassword';
import PerformResetPassword from '../ResetPassword/PerformResetPassword/PerformResetPassword';

const JerseyConfig = lazy(() => import('../JerseyConfig/JerseyConfig'));
const Admin = lazy(() => import('../Admin/Admin'));
const JerseyTypePicking = lazy(() => import('../Home/JerseyTypePicking/JerseyTypePicking'));
const JerseyShare = lazy(() => import('../JerseyShare/JerseyShare'));
const JerseyAdminPreview = lazy(() => import('../JerseyAdminPreview/JerseyAdminPreview'));

const StyledApp = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

type StyledMainProps = {
  imgUrl: string;
};

const StyledMain = styled.main<StyledMainProps>`
  background: ${({ imgUrl }) => `url(${imgUrl}) no-repeat center center fixed`};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
  flex: 1;
  display: flex;
  overflow-y: auto;
`;

export const App: FC = () => {
  const { t } = useTranslation(['home', 'commons']);
  const toastId = useRef<any>(null);

  const history = useHistory();

  useEffect(() => {
    if (history) {
      history.listen(() => {
        toast.dismiss();
      });
    }
  }, [history]);

  const dispatch = useDispatch();

  const userId = useAppSelector((state) => state.admin.id);
  const teamNameStatus = useAppSelector((state) => state.teamName.status);
  const errorMessage = useAppSelector((state) => state.error.message);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const isRedirected = params.redirected;

    if (isRedirected) {
      dispatch(setError('needToSignInAgain'));
    }
  }, []);

  useEffect(() => {
    // add to if statement && !toast.isActive(toastId.current) - to prevent multiple error toast
    if (errorMessage) {
      const translationKey = `commons:apiErrorMessages.${errorMessage}`;
      const errMsg = t(translationKey as any, 'Vyskytla se chyba, zkuste to prosím později.');
      toastId.current = toast(`${errMsg}`, {
        toastId: nanoid(),
        type: 'error',
      });
      dispatch(clearErrors());
      if (errorMessage === 'userNotFound') {
        localStorage.removeItem(authTokenName);
        localStorage.removeItem(refreshTokenName);
      }
    }
  }, [errorMessage, toastId]);

  useEffect(() => {
    const token = handleJWTValidity(authTokenName);
    if (token) {
      dispatch(setAuthToken(token));
      if (userId) {
        dispatch(getUser({ id: userId }));
      }
      setIsLoaded(true);
    } else {
      const refreshToken = handleJWTValidity(refreshTokenName);
      if (refreshToken) {
        refreshAccessToken()
          .then((res) => {
            const { accessToken, refreshToken: newRefreshToken } = res as any;
            // eslint-disable-next-line no-unused-expressions
            newRefreshToken
              ? window.localStorage.setItem(refreshTokenName, newRefreshToken)
              : window.localStorage.removeItem(refreshTokenName);
            // eslint-disable-next-line no-unused-expressions
            accessToken
              ? window.localStorage.setItem(authTokenName, accessToken)
              : window.localStorage.removeItem(authTokenName);
            dispatch(setAuthToken(accessToken));
            setIsLoaded(true);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
            setIsLoaded(true);
          });
      } else {
        setIsLoaded(true);
      }
    }
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(changeDevice({ width: window.innerWidth, height: window.innerHeight }));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(changeDevice({ width: window.innerWidth, height: window.innerHeight }));
    });
  }, [dispatch]);

  const addAccessTokenToEveryRequest = () => {
    axios.interceptors.request.use(
      async (config) => {
        const accessToken = window.localStorage.getItem(authTokenName);

        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        };
        return { ...config, headers };
      },
      (error) => {
        Promise.reject(error);
      }
    );
  };

  addAccessTokenToEveryRequest();
  axiosInterceptorRefreshToken(dispatch);

  return (
    <StyledApp>
      <Header />
      {isLoaded && (
        <StyledMain imgUrl={appBg}>
          <Switch>
            <Route exact path="/" component={JerseyTypePicking} />
            <Route path="/config/:id" component={JerseyConfig} />
            <Route path="/admin" component={Admin} />
            <Route path="/admin-preview/:path" component={JerseyAdminPreview} />
            <Route path="/share/:path" component={JerseyShare} />
            <Route path="/order/:id(\d+)?" component={OrderStatus} />
            <Route path="/reset-password/:resetToken" component={PerformResetPassword} />
            <Route path="/reset-password" component={RequestResetPassword} />
            <Redirect to="/" />
          </Switch>
        </StyledMain>
      )}
      {teamNameStatus === 'loading' && <ProjectLoadingScreen />}
      <ErrorToast />
    </StyledApp>
  );
};
