import { FC } from 'react';
import styled from 'styled-components';
import { RoundedButton } from './RoundedButton';

type IconPosition = 'left' | 'right';

type StyledActionButtonProps = {
  iconposition: IconPosition;
};

const StyledActionButton = styled(RoundedButton)<StyledActionButtonProps>`
  height: 100%;
  padding: ${({ iconposition }) =>
    iconposition === 'right' ? '0.15rem 0.3rem 0.15rem 0.8rem;' : '0.15rem 0.8rem 0.15rem 0.3rem;'};
  /* border: 1px solid var(--color-success); */
  /* &:active {
    color: var(--color-light);
    background-color: var(--color-success);
  } */

  & > svg {
    width: 1.5rem;
    height: 2rem;
  }
`;

type Props = {
  className?: string;
  iconPosition?: IconPosition;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (...args: any[]) => any;
  isShadow?: boolean;
  color?: string;
};

export const ActionButton: FC<Props> = (
  { children, className, iconPosition = 'right', type = 'button', onClick, isShadow = false, color = 'success' },
  props
) => (
  <StyledActionButton
    iconposition={iconPosition}
    className={className}
    type={type}
    onClick={onClick}
    color={color}
    fill={isShadow ? 'shadow' : 'outline'}
    {...props}
  >
    {children}
  </StyledActionButton>
);
