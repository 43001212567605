import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatternPreviewsList, PatternRes } from '../types';
import { createAsyncAction, createCustomAsyncThunk, createUrlToStaticFile } from '../../utils';
import { patchProject } from '../reusableActions';
import { fetchProjectByPath } from '../sharedActions';

const resetState = createAction<null>('jerseyConfig/resetState');

const sliceName = 'addons';

const asyncActions = {
  fetchSocksList: 'fetchSocksList',
  fetchSockPattern: 'fetchSockPattern',
  fetchSocksTypes: 'fetchSocksTypes',
  patchAreSocksIncluded: 'patchAreSocksIncluded',
  patchSocksType: 'patchSocksType',
  patchSockPattern: 'patchSockPattern',
};

type AddonsState = {
  status: 'idle' | 'loading' | 'failed';
  socksList: PatternPreviewsList;
  socksTypes: { type: string; labelText: string; id: number }[];
  selectedSocks: {
    id: number | null;
    content: any | null;
    activeColors: number;
    type: string;
    isIncluded: boolean;
  };
};

const initialState: AddonsState = {
  status: 'idle',
  socksList: [],
  socksTypes: [],
  selectedSocks: {
    id: null,
    content: '',
    activeColors: 0,
    type: 'knitted',
    isIncluded: false,
  },
};
const setSockPattern = (state: AddonsState, payload: PatternRes) => {
  state.selectedSocks.id = payload.id;
  state.selectedSocks.content = payload.svgFront;
  state.selectedSocks.activeColors = payload.colorCount;
};

function createItemNameByLang<T extends object>(item: T, lang: string | undefined = 'cs'): string {
  const nameKey: keyof T = `name${lang.charAt(0).toUpperCase() + lang.slice(1)}` as keyof T;
  const name = item[nameKey];
  if (typeof name === 'string') return name as string;
  return '';
}

const createPatternOrSockList = (payload: {
  list: PatternRes[];
  selectedId: number;
}): { id: number; url: string; isSelected: boolean; name: string }[] =>
  payload.list.map((pattern) => {
    if (pattern.id === payload.selectedId) {
      return {
        id: pattern.id,
        url: createUrlToStaticFile({
          id: pattern.staticFile.id,
          folderName: pattern.staticFile.folderName,
          ext: pattern.staticFile.extension,
        }),
        isSelected: true,
        name: createItemNameByLang(pattern),
      };
    }
    return {
      id: pattern.id,
      url: createUrlToStaticFile({
        id: pattern.staticFile.id,
        folderName: pattern.staticFile.folderName,
        ext: pattern.staticFile.extension,
      }),
      isSelected: false,
      name: createItemNameByLang(pattern),
    };
  });

type FetchSocksTypeRes = { data: { id: number; nameCs: string; nameEn: string; type: string }[] };

export const fetchSocksTypes = createCustomAsyncThunk<FetchSocksTypeRes, {}>(
  createAsyncAction(sliceName, asyncActions.fetchSocksTypes),
  {
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/sock-types`,
  }
);

type PatchAreSocksIncludedRes = {
  path: string;
  areSocksIncluded: boolean;
};

type PatchAreSocksIncludedParams = { path: string; areSocksIncluded: boolean };

export const patchAreSocksIncluded = patchProject<PatchAreSocksIncludedRes, PatchAreSocksIncludedParams>(
  createAsyncAction(sliceName, asyncActions.patchAreSocksIncluded)
);

type PatchSocksTypeRes = {
  path: string;
  socksTypeId: number;
  socksType: string;
};

type PatchSocksTypeParams = { path: string; socksTypeId: number; toIncludeInRes: 'socksType' };

export const patchSocksType = patchProject<PatchSocksTypeRes, PatchSocksTypeParams>(
  createAsyncAction(sliceName, asyncActions.patchSocksType)
);

type PatchSockPatternParams = {
  path: string;
  socksPatternId: number;
  toIncludeInRes: 'socksPattern';
};

type PatchSockPatternRes = PatchSockPatternParams & {
  socksPattern: PatternRes;
};

export const patchSockPattern = patchProject<PatchSockPatternRes, PatchSockPatternParams>(
  createAsyncAction(sliceName, asyncActions.patchSockPattern)
);

export const addonsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    changeSocksType(state, action: PayloadAction<string>) {
      state.selectedSocks.type = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(resetState, (_, _1) => initialState)
      .addCase(fetchSocksTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSocksTypes.fulfilled, (state, action) => {
        state.status = 'idle';
        state.socksTypes = action.payload.data.map((sockType) => ({
          type: sockType.type,
          labelText: createItemNameByLang(sockType),
          id: sockType.id,
        }));
      })
      .addCase(fetchProjectByPath.fulfilled, (state, action) => {
        state.selectedSocks.isIncluded = action.payload.areSocksIncluded;
        state.selectedSocks.type = action.payload.socksType.type;
        state.socksList = createPatternOrSockList({
          list: action.payload.socks,
          selectedId: action.payload.socksPattern.id,
        });
        setSockPattern(state, action.payload.socksPattern);
      })
      .addCase(patchAreSocksIncluded.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchAreSocksIncluded.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedSocks.isIncluded = action.payload.areSocksIncluded;
      })
      .addCase(patchSocksType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchSocksType.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedSocks.type = action.payload.socksType;
      })
      .addCase(patchSockPattern.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchSockPattern.fulfilled, (state, action) => {
        state.status = 'idle';
        const { socksPattern } = action.payload;
        setSockPattern(state, socksPattern);
        state.socksList = state.socksList.map((pattern) => {
          if (pattern.id === action.payload.socksPatternId) return { ...pattern, isSelected: true };
          return { ...pattern, isSelected: false };
        });
      }),
});

export const { changeSocksType } = addonsSlice.actions;

export const addonsSliceReducer = addonsSlice.reducer;
