import { ToastContainer } from 'react-toastify';

const ErrorToast = () => (
  <ToastContainer
    position="top-right"
    autoClose={false}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export default ErrorToast;
