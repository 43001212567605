import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { JerseyType, PatternRes } from './types';
import { FetchProject, PriceConstants } from '../../types';

// file naming took from - https://github.com/reduxjs/redux-toolkit/issues/562

export const fetchProjectByIdAction = createAction('shared/fetchProjectById');
// ASYNC THUNKS

type FetchProjectByPathArgs = { path: string; jerseyTypeId: JerseyType };

export const fetchProjectByPath = createAsyncThunk<FetchProject, FetchProjectByPathArgs>(
  fetchProjectByIdAction.toString(),
  async ({ path, jerseyTypeId }) => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/projects/${path}`);
    const patternsRes = await axios.get<{ data: PatternRes[] }>(
      `${process.env.REACT_APP_API_URL}/patterns?type=${jerseyTypeId}Jersey`
    );
    const colorsRes = await axios.get(`${process.env.REACT_APP_API_URL}/colors`);
    const fontsRes = await axios.get(`${process.env.REACT_APP_API_URL}/fonts`);
    const socksRes = await axios.get<{ data: PatternRes[] }>(
      `${process.env.REACT_APP_API_URL}/patterns?type=${jerseyTypeId}Socks`
    );
    const pricesRes = await axios.get<{ data: PriceConstants }>(`${process.env.REACT_APP_API_URL}/prices`);

    if (!res.data.data.path) throw new Error('No data!');

    return {
      ...res.data.data,
      patterns: [...patternsRes.data.data],
      colors: [...colorsRes.data.data],
      fonts: [...fontsRes.data.data],
      socks: [...socksRes.data.data],
      priceConstants: { ...pricesRes.data.data },
    };
  }
);
