import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { patchProject } from '../reusableActions';
import { createAsyncAction, createCustomAsyncThunk, createUrlToStaticFile } from '../../utils';
import { FontFamilyRes, JerseyLabelType, TextRes } from '../types';
import { fetchProjectByPath } from '../sharedActions';

const sliceName = 'textConfig';
const asyncActions = {
  patchFontFamily: 'patchFontFamily',
  patchTextExampleValue: 'patchTextExampleValue',
  patchTextType: 'patchTextType',
  patchTextColor: 'patchTextColor',
  patchTextIsActive: 'patchTextIsActive',
  patchSuperjerseyLogoIsActive: 'patchSuperjerseyLogoIsActive',
  patchJerseyLabelType: 'patchJerseyLabelType',
};

const resetState = createAction<null>('jerseyConfig/resetState');
const changePatternColor = createAction<{
  id: number;
  value: string;
  activeColorIndex: number;
}>('jerseyConfig/changePatternColor');

type JerseyTextValue = {
  id: number;
  isActive: boolean;
  exampleValue: string;
  color: {
    selectedColorId: number;
    value: string;
    isActive: boolean;
  }[];
  activeColorIndex: number;
  type: 'fill' | 'fill+stroke';
};

type JerseySectionCheckbox = boolean;

export type Colors = {
  id: number;
  value: string;
  isActive: boolean;
  selectedColorId: number;
}[];

type FontFamily = {
  id: number | null;
  name: string;
  sampleText: string;
  url: string;
  isDefault: boolean;
};

type TextConfigState = {
  status: 'idle' | 'loading' | 'failed';
  superjerseysLogoSvg: string;
  fontFamily: FontFamily;
  backName: JerseyTextValue & { charsPerLine: number; textBaseSize: number };
  backNumber: JerseyTextValue;
  sleevesNumber: JerseyTextValue;
  isBackNameActive: JerseySectionCheckbox;
  superjerseysLogoAddon: { isActive: boolean };
  /* fontOptions: FontOption[] | []; */
  jerseyLabelType: JerseyLabelType;
};

const initialState: TextConfigState = {
  status: 'idle',
  superjerseysLogoSvg: '',
  fontFamily: {
    id: null,
    name: '',
    sampleText: '',
    url: '',
    isDefault: false,
  },
  isBackNameActive: false,
  backName: {
    id: 0,
    isActive: true,
    exampleValue: 'PASTA',
    charsPerLine: 5,
    textBaseSize: 200,
    color: [
      {
        selectedColorId: 0,
        value: '#ffffff',
        isActive: true,
      },
      {
        selectedColorId: 0,
        value: '#000000',
        isActive: false,
      },
    ],
    activeColorIndex: 0,
    type: 'fill',
  },
  backNumber: {
    id: 0,
    isActive: true,
    exampleValue: '21',
    color: [
      {
        selectedColorId: 0,
        value: '#ffffff',
        isActive: true,
      },
      {
        selectedColorId: 0,
        value: '#000000',
        isActive: false,
      },
    ],
    activeColorIndex: 0,
    type: 'fill',
  },
  sleevesNumber: {
    id: 0,
    isActive: true,
    exampleValue: '21',
    color: [
      {
        selectedColorId: 0,
        value: '#ffffff',
        isActive: true,
      },
      {
        selectedColorId: 0,
        value: '#000000',
        isActive: false,
      },
    ],
    activeColorIndex: 0,
    type: 'fill',
  },
  superjerseysLogoAddon: {
    isActive: false,
  },
  /*  fontOptions: [], */
  jerseyLabelType: 'none',
};

type Key = 'backName' | 'sleevesNumber' | 'backNumber';

const updateTextColor = (state: any, payload: { id: number; value: string; activeColorIndex: number; key: Key }) => {
  const { key, value, id, activeColorIndex } = payload;
  state[key].color[activeColorIndex].value = value;
  state[key].color[activeColorIndex].selectedColorId = id;
};

// THUNKS

type PatchFontFamilyParams = {
  path: string;
  fontId: number;
  toIncludeInRes: 'font';
};

type PatchFontFamilyRes = PatchFontFamilyParams & {
  font: FontFamilyRes & { charsPerLine: number; textBaseSize: number };
};

export const patchFontFamily = patchProject<PatchFontFamilyRes, PatchFontFamilyParams>(
  createAsyncAction(sliceName, asyncActions.patchFontFamily)
);

type PatchTextParams = {
  data: {
    id: number;
  };
  path: string;
};

export const patchText = <T extends unknown, U extends PatchTextParams>(actionName: string) =>
  createCustomAsyncThunk<T, U>(actionName, {
    method: 'PATCH',
    url: ({ data, path }) => `${process.env.REACT_APP_API_URL}/texts/${data.id}?path=${path}`,
  });

type PatchTextExampleValueParams = {
  data: {
    id: number;
    content: string;
  };
  key: Key;
  path: string;
};

type PatchTextExampleRes = {
  id: number;
  content: string;
  key: Key;
};

export const patchTextExampleValue = patchText<PatchTextExampleRes, PatchTextExampleValueParams>(
  createAsyncAction(sliceName, asyncActions.patchTextExampleValue)
);

type PatchTextTypeParams = {
  data: {
    id: number;
    hasCountour: boolean;
  };
  key: Key;
  path: string;
};

type PatchTextTypeRes = {
  id: number;
  hasCountour: boolean;
  key: Key;
};

export const patchTextType = patchText<PatchTextTypeRes, PatchTextTypeParams>(
  createAsyncAction(sliceName, asyncActions.patchTextType)
);

type PatchTextColorParams = {
  data: {
    id: number;
    fillColorId?: number;
    contourColorId?: number;
  };
  key: Key;
  value: string;
  path: string;
};

type PatchTextColorRes = {
  id: number;
  fillColorId?: number;
  contourColorId?: number;
  key: Key;
  value: string;
};

export const patchTextColor = patchText<PatchTextColorRes, PatchTextColorParams>(
  createAsyncAction(sliceName, asyncActions.patchTextColor)
);

type PatchTextIsActiveParams = {
  data: {
    id: number;
    isShown: boolean;
  };
  key: Key;
  path: string;
};

type PatchTextIsActiveRes = {
  id: number;
  isShown: boolean;
  key: Key;
};

export const patchTextIsActive = patchText<PatchTextIsActiveRes, PatchTextIsActiveParams>(
  createAsyncAction(sliceName, asyncActions.patchTextIsActive)
);

type PatchSuperjerseyLogoIsActiveParams = {
  path: string;
  isSuperjerseyLogoShown: boolean;
};

type PatchSuperjerseyLogoIsActiveRes = {
  path: string;
  isSuperjerseyLogoShown: boolean;
};

export const patchSuperjerseyLogoIsActive = patchProject<
  PatchSuperjerseyLogoIsActiveRes,
  PatchSuperjerseyLogoIsActiveParams
>(createAsyncAction(sliceName, asyncActions.patchSuperjerseyLogoIsActive));

type PatchJerseyLabelTypeParams = {
  path: string;
  option: 'C' | 'A' | 'none';
};

type PatchJerseyLabelTypeRes = PatchJerseyLabelTypeParams;

export const patchJerseyLabelType = patchProject<PatchJerseyLabelTypeRes, PatchJerseyLabelTypeParams>(
  createAsyncAction(sliceName, asyncActions.patchJerseyLabelType)
);

// END OF THUNKS

// reusable actions

const updateFontFamily = (state: TextConfigState, font: FontFamilyRes) => {
  state.fontFamily = {
    id: font.id,
    name: font.name,
    sampleText: font.sampleText,
    url: createUrlToStaticFile({
      id: font.id,
      folderName: font.staticFile.folderName,
      ext: font.staticFile.extension,
    }),
    isDefault: font.isDefault,
  };
};

const convertHasCountourToTextType = (hasContour: boolean) => (hasContour ? 'fill+stroke' : 'fill');

const createTextUpdate = (textRes: TextRes): JerseyTextValue => ({
  id: textRes.id,
  isActive: textRes.isShown,
  exampleValue: textRes.content.toUpperCase(),
  color: [
    {
      selectedColorId: textRes.textFillColor.id,
      value: textRes.textFillColor.value,
      isActive: true,
    },
    {
      selectedColorId: textRes.textContourColor.id,
      value: textRes.textContourColor.value,
      isActive: textRes.hasCountour,
    },
  ],
  activeColorIndex: 0,
  type: convertHasCountourToTextType(textRes.hasCountour),
});

// end of reusable actions

export const textConfigSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    changeTextSectionActiveColorIndex(state, action: PayloadAction<{ id: number; key: Key }>) {
      const { key, id } = action.payload;
      state[key].activeColorIndex = id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetState, (_, _1) => initialState)
      .addCase(changePatternColor, (state, action) => {
        if (action.payload.activeColorIndex <= 1) {
          updateTextColor(state, { ...action.payload, key: 'backName' });
          updateTextColor(state, { ...action.payload, key: 'backNumber' });
          updateTextColor(state, { ...action.payload, key: 'sleevesNumber' });
        }
      })
      .addCase(patchFontFamily.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchFontFamily.fulfilled, (state, action) => {
        const { font } = action.payload;
        updateFontFamily(state, font);
        state.backName.charsPerLine = font.charsPerLine;
        state.backName.textBaseSize = font.textBaseSize;
      })
      .addCase(fetchProjectByPath.fulfilled, (state, action) => {
        const { font, projectName, backText, sleevesText } = action.payload;
        updateFontFamily(state, font);
        state.superjerseysLogoSvg = action.payload.superjerseysLogo.svg;
        state.backName = {
          ...createTextUpdate(projectName),
          charsPerLine: font.charsPerLine,
          textBaseSize: font.textBaseSize,
        };
        state.backNumber = createTextUpdate(backText);
        state.sleevesNumber = createTextUpdate(sleevesText);
        state.superjerseysLogoAddon.isActive = action.payload.isSuperjerseyLogoShown;
        state.jerseyLabelType = action.payload.option;
      })
      .addCase(patchTextExampleValue.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchTextExampleValue.fulfilled, (state, action) => {
        state.status = 'idle';
        state[action.payload.key].exampleValue = action.payload.content;
      })
      .addCase(patchTextType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchTextType.fulfilled, (state, action) => {
        state.status = 'idle';
        const { hasCountour, key } = action.payload;
        state[key].type = convertHasCountourToTextType(hasCountour);
        if (hasCountour) {
          state[key].color[0].isActive = true;
          state[key].color[1].isActive = true;
        } else {
          state[key].color[0].isActive = true;
          state[key].color[1].isActive = false;
          state[key].activeColorIndex = 0;
        }
      })
      .addCase(patchTextColor.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchTextColor.fulfilled, (state, action) => {
        const { contourColorId, fillColorId, value, key } = action.payload;
        if (contourColorId) {
          state[key].color[1].value = value;
          state[key].color[1].selectedColorId = contourColorId;
        }
        if (fillColorId) {
          state[key].color[0].value = value;
          state[key].color[0].selectedColorId = fillColorId;
        }
      })
      .addCase(patchTextIsActive.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchTextIsActive.fulfilled, (state, action) => {
        const { key, isShown } = action.payload;
        state.status = 'idle';
        state[key].isActive = isShown;
      })
      .addCase(patchSuperjerseyLogoIsActive.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchSuperjerseyLogoIsActive.fulfilled, (state, action) => {
        state.status = 'idle';
        state.superjerseysLogoAddon.isActive = action.payload.isSuperjerseyLogoShown;
      })
      .addCase(patchJerseyLabelType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchJerseyLabelType.fulfilled, (state, action) => {
        state.status = 'idle';
        state.jerseyLabelType = action.payload.option;
      });
  },
});

export const { changeTextSectionActiveColorIndex } = textConfigSlice.actions;

export const textConfigReducer = textConfigSlice.reducer;
