import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input } from '../../UI/Input';
import { RoundedButton } from '../../UI/RoundedButton';
import useHttp from '../../hooks/http';
import { LoadingSpinnerOverlay } from '../../UI/LoadingSpinnerOverlay';
import { getApiErrorMessage } from '../../utils';

const StyledRequestResetPassword = styled.form`
  background-color: var(--color-light);
  margin: auto;
  padding: 3rem;
  & > * {
    margin-bottom: 2rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const StyledSuccessMessage = styled.p`
  font-size: var(--font-size-medium);
  color: var(--color-success);
  font-weight: bold;
`;

const StyledErrorMessage = styled.p`
  color: var(--color-danger);
`;

type FormData = {
  email: string;
};

const requestIdentifiers = {
  postResetRequest: 'postResetRequest',
};

const RequestResetPassword = () => {
  const { t } = useTranslation(['commons', 'resetPassword']);

  const { sendRequest, data, error, isLoading } = useHttp();

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email(t('commons:errorMessages.emailNotValidErr'))
          .required(t('commons:errorMessages.emailRequiredErr')),
      })
    ),
  });

  const onSubmit = handleSubmit(async (formData) => {
    sendRequest({
      url: `${process.env.REACT_APP_API_URL}/reset-password`,
      method: 'POST',
      body: {
        email: formData.email,
      },
      reqIdentifer: requestIdentifiers.postResetRequest,
    });
  });

  return (
    <StyledRequestResetPassword onSubmit={onSubmit}>
      {data ? (
        <StyledSuccessMessage>{t('resetPassword:emailHasBeenSent')}</StyledSuccessMessage>
      ) : (
        <>
          <h2>{t('resetPassword:resetPasswordRequest')}</h2>
          <Input
            placeholder="E-mail"
            register={register}
            name="email"
            errors={errors}
            label={t('resetPassword:resetPasswordRequestLabel')}
          />
          <RoundedButton fill="full" type="submit" color="primary">
            {t('resetPassword:resetPasswordRequestButton')}
          </RoundedButton>
        </>
      )}
      {error && <StyledErrorMessage>{getApiErrorMessage(error.message, t)}</StyledErrorMessage>}
      {isLoading && <LoadingSpinnerOverlay />}
    </StyledRequestResetPassword>
  );
};

export default RequestResetPassword;
