import styled from 'styled-components';
import { LoadingSpinner } from './LoadingSpinner';

export const StyledLoadingSpinnerOverlay = styled.div`
  position: absolute;
  background-color: rgba(var(--color-dark), 0.5);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingSpinnerOverlay = () => (
  <StyledLoadingSpinnerOverlay>
    <LoadingSpinner width="4rem" height="4rem" />
  </StyledLoadingSpinnerOverlay>
);
