import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

type StyledLoadingSpinnerProps = {
  height: string;
  width: string;
};

const rotateSpinner = keyframes`
    from{ transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const StyledLoadingSpinner = styled.div<StyledLoadingSpinnerProps>`
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid var(--color-primary);
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  animation: ${rotateSpinner} 2s linear infinite;
`;

type Props = {
  width?: string;
  height?: string;
  className?: string;
};

export const LoadingSpinner: FC<Props> = ({ width = '3rem', height = '3rem', className }) => (
  <StyledLoadingSpinner width={width} height={height} className={className} />
);
