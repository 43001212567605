import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SignInLink } from './SignInLink';
import appLogo from '../../../assets/images/app-logo.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { postSignout, selectIsAuth } from '../../Admin/adminSlice';
import { refreshTokenName } from '../../utils';
import { RootState } from '../../../store/store';

const StyledHeader = styled.header`
  background-color: var(--color-light);
  display: flex;
  align-items: center;
  padding: var(--spacing-mobile);
`;

const StyledLogoLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 33px;
  &:visited {
    color: #000000;
  }
  & img {
    width: auto;
    height: 100%;
  }
`;

export const SignoutLink = styled(Link)`
  font-weight: bold;
  padding: 0 1rem;
  height: 100%;
  margin-left: 0.5rem;
  text-decoration: none;
  color: var(--color-medium);
  border: 1px solid var(--color-medium);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    color: var(--color-light);
    background-color: var(--color-medium);
  }
`;

const Header = () => {
  const location = useLocation();
  const { t } = useTranslation(['home']);

  const dispatch = useAppDispatch();

  const shouldHide = location.pathname.startsWith('/share') || location.pathname.startsWith('/admin-preview');
  const userId = useAppSelector((state: RootState) => state.admin.id);
  const isAuth = useAppSelector(selectIsAuth);

  const handleSignout = () => {
    if (!userId) {
      // eslint-disable-next-line
      console.error('no user id!');
      return;
    }
    dispatch(postSignout({ data: { refreshToken: localStorage.getItem(refreshTokenName) ?? '', userId } }));
  };

  return (
    <>
      {!shouldHide && (
        <StyledHeader>
          <h1>
            <StyledLogoLink to="/">
              <img src={appLogo} alt="app logo" />
            </StyledLogoLink>
          </h1>
          <div style={{color:"black", textAlign:"right", width: "100vw", marginRight:"1em", marginLeft:"-5em"}}>
          <a href="https://www.customisee.com/kontakt" id="kontakt" target="_blank" style={{color:"black", marginRight:"30px", display:"inline-block"}}><span>Kontakt</span></a>
            <a href="https://www.customisee.com/zasady-ochrany-osobnich-udaju" target="_blank" style={{color:"black", display:"inline-block"}}><span id="vopLabel">Obchodní podmínky</span>
          <span id="vopLabelZkrt" style={{display:"none"}}>VOP</span></a></div>
          <SignInLink />
          {isAuth && (
            <SignoutLink onClick={handleSignout} to={{ pathname: '/' }}>
              <span id="disableMobileTextReg">{t('home:logout')}</span>
              <span id="disableMobileTextRegZkrt" style={{display:"none"}}>➡</span>
            </SignoutLink>
          )}
        </StyledHeader>
      )}
    </>
  );
};

export default Header;