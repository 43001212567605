import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import home from '../public/locales/cs/home.json';
import admin from '../public/locales/cs/admin.json';
import jerseyConfig from '../public/locales/cs/jerseyConfig.json';
import orderProcess from '../public/locales/cs/orderProcess.json';
import order from '../public/locales/cs/order.json';
import commons from '../public/locales/cs/commons.json';
import ui from '../public/locales/cs/ui.json';
import share from '../public/locales/cs/share.json';
import resetPassword from '../public/locales/cs/resetPassword.json';

declare module 'react-i18next' {
  // and extend them!
  interface Resources {
    home: typeof home;
    admin: typeof admin;
    jerseyConfig: typeof jerseyConfig;
    commons: typeof commons;
    orderProcess: typeof orderProcess;
    order: typeof order;
    ui: typeof ui;
    share: typeof share;
    resetPassword: typeof resetPassword;
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'cs',
    debug: false,
    ns: ['jerseyConfig', 'home', 'admin', 'commons', 'orderProcess', 'order', 'ui', 'share', 'resetPassword'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
