import { getTrainingSetColor } from './getTrainingSetColor';

type Color = { value: string; isActive: boolean };

export const getTrainingPatternColors = (colors: Color[] | Color) => {
  if (Array.isArray(colors)) {
    return {
      color0: getTrainingSetColor(colors).value,
      color1: undefined,
      color2: undefined,
      color3: undefined,
    };
  }
  return {
    color0: colors.value,
    color1: undefined,
    color2: undefined,
    color3: undefined,
  };
};
