import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoadingSpinner } from '../../UI/LoadingSpinner';
import { useAppSelector } from '../../hooks/reduxHooks';

export const StyledProjectLoadingScreen = styled.div`
  position: absolute;
  background-color: rgba(var(--color-dark), 0.5);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContent = styled.div`
  background-color: var(--color-light);
  padding: 2rem;
  border-radius: var(--radius-bigger);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
`;

const StyledText = styled.p`
  margin-top: 1rem;
`;

const ProjectLoadingScreen = () => {
  const { t } = useTranslation(['home']);

  const loadingId = useAppSelector((state) => state.teamName.loadingId);

  return (
    <StyledProjectLoadingScreen>
      <StyledContent>
        <LoadingSpinner width="4rem" height="4rem" />
        <StyledText>{loadingId === 'fetch' ? t('home:loadingProject') : t('home:creatingProject')}</StyledText>
      </StyledContent>
    </StyledProjectLoadingScreen>
  );
};

export default ProjectLoadingScreen;
