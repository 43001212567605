import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { breakpoint } from '../utils';

type AppSlice = {
  device: 'mobile' | 'desktop' | undefined;
};

const initialState: AppSlice = {
  device: undefined,
};

export const appSlice = createSlice({
  name: 'jerseyConfig',
  initialState,
  reducers: {
    changeDevice(state, action: PayloadAction<{ width: number; height: number }>) {
      if (action.payload.width < breakpoint.tablet) {
        state.device = 'mobile';
      } else {
        state.device = 'desktop';
      }
    },
  },
});

export const { changeDevice } = appSlice.actions;

export const appReducer = appSlice.reducer;
