import { createSlice } from '@reduxjs/toolkit';
import { createAsyncAction, createCustomAsyncThunk } from '../../utils';
import { Country, FapiForm, FapiOrder, PaymentMethod, Purpose, ShippingMethod } from './types';

const sliceName = 'fapi';

const asyncActions = {
  getForm: 'getForm',
  getCountryList: 'getCountryList',
  postOrderByOrderId: 'postOrderByOrderId'
};

type FapiState = {
  status: 'idle' | 'loading' | 'failed'

  termsUrl?: string
  countries: Country[]
  shippingMethods: ShippingMethod[]
  paymentMethods: PaymentMethod[]
  purposes: Purpose[]
};

const initialState: FapiState = {
  status: 'idle',

  countries: [{ code: 'CZ', name: 'Česká Republika' }],
  shippingMethods: [],
  paymentMethods: [],
  purposes: []
};

// GET
type GetFormRes = FapiForm;
type GetFormArgs = {};

export const getForm = createCustomAsyncThunk<GetFormRes, GetFormArgs>(
  createAsyncAction(sliceName, asyncActions.getForm),
  {
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/fapi`
  }
);

type GetCountryListRes = { data: Country[] };
type GetCountryListArgs = {};

export const getCountryList = createCustomAsyncThunk<GetCountryListRes, GetCountryListArgs>(
  createAsyncAction(sliceName, asyncActions.getCountryList),
  {
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/fapi/countries`
  }
);

// POST
type PostOrderRes = FapiOrder;
type PostOrderArgs = { orderId: number };

export const postOrderByOrderId = createCustomAsyncThunk<PostOrderRes, PostOrderArgs>(
  createAsyncAction(sliceName, asyncActions.postOrderByOrderId),
  {
    method: 'POST',
    url: ({ orderId }) => `${process.env.REACT_APP_API_URL}/fapi/orders/${orderId}`
  }
);

export const fapiSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder => (
    builder
      .addCase(getForm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getForm.fulfilled, (state, action) => {
        state.status = 'idle';

        state.termsUrl = action.payload.terms_url;

        const resolveNameByType = (paymentType: string) => {
          switch (paymentType) {
            case 'wire':
              return 'Bankovní převod';  // TODO klíč na překlad
            case 'cash':
              return 'Platba v hotovosti';  // TODO klíč na překlad
            case 'collect on delivery':
              return 'Dobírka';  // TODO klíč na překlad
            default:
              return paymentType;
          }
        };

        state.paymentMethods = action.payload.payment_methods.map((paymentMethod, index) => <PaymentMethod>({
          id: index, // pro vykreslování v formuláři
          name: paymentMethod.name ?? resolveNameByType(paymentMethod.payment_type), // TODO klíč na překlad
          paymentType: paymentMethod.payment_type,
          bank: paymentMethod.bank,
          currencies: paymentMethod.currencies,
          countries: paymentMethod.countries,
          imageUrl: paymentMethod.image_url,
          isOnline: paymentMethod.is_online,
          group: paymentMethod.group
        }));

        state.shippingMethods = action.payload.shipping_methods.map(shippingMethod => <ShippingMethod>({
          id: shippingMethod.id,
          name: shippingMethod.name,
          type: shippingMethod.type,
          description: shippingMethod.description,
          addressPrices: shippingMethod.address_prices.map(addressPrice => ({
            address: {
              simpleAddress: addressPrice.address.simple_address,
              countryCode: addressPrice.address.country_code
            },
            prices: addressPrice.prices.map(price => ({
              currencyCode: price.currency_code,
              price: price.price
            }))
          })),
          includingVat: shippingMethod.including_vat
        }));

        state.purposes = action.payload.purposes.map(purpose => ({
          id: purpose.id,
          isPrimary: purpose.is_primary,
          html: purpose.html,
          required: purpose.required
        }));
      })
      .addCase(getForm.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(getCountryList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.countries = action.payload.data;
      })
      .addCase(getCountryList.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(postOrderByOrderId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postOrderByOrderId.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(postOrderByOrderId.rejected, (state) => {
        state.status = 'failed';
      })
  ))
});

export const fapiReducer = fapiSlice.reducer;

