export const createFrontLogoDefaultObject = (size: number, posY: number) => ({
  id: null,
  data: '',
  url: '',
  size,
  posY,
  isDefault: true,
  originalWidth: null,
  originalHeight: null,
  fileName: '',
  fileSize: null,
  isUsersOwnLogo: false,
  extension: '',
  colors: [],
  colorCount: 0,
  viewboxWidth: 1738.04,
  viewboxHeight: 2160,
  posYMax: null,
  posYMin: null,
  isOutsideGivenSpace: false,
});
