import { FC } from 'react';

import { RoundedButton } from '../../UI/RoundedButton';
import { FormType } from './types';

type Props = {
  switchToType: FormType;
  onClick: (type: FormType) => void;
};

export const SwitchFormButton: FC<Props> = ({
  children,
  switchToType,
  onClick,
}) => (
    <RoundedButton
      fill="outline"
      color="medium"
      onClick={() => onClick(switchToType)}
    >
      {children}
    </RoundedButton>
  );
