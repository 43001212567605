import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction, createCustomAsyncThunk, createUrlToStaticFile } from '../../../utils';
import { OrderAddon } from './types';
import { RootState } from '../../../../store/store';

const sliceName = 'orderAddons';

const asyncActions = {
  // getDefaultAddons: 'getDefaultAddons',
  getOrderAddonList: 'getOrderAddonList',
  postOrderAddonList: 'postOrderAddonList',
  patchOrderAddonList: 'patchOrderAddonList',
  deleteOrderAddonList: 'deleteOrderAddonList',
};

type OrderState = {
  status: 'idle' | 'loading' | 'failed';
  isPostFinished: boolean;
  addons: OrderAddon[];
};

const initialState: OrderState = {
  status: 'idle',
  isPostFinished: false,
  addons: [],
};

// GET
type GetOrderAddonListRes = { data: OrderAddon[] };
type GetOrderAddonListArgs = { orderId: number; useDefaults?: boolean };

export const getOrderAddonList = createCustomAsyncThunk<GetOrderAddonListRes, GetOrderAddonListArgs>(
  createAsyncAction(sliceName, asyncActions.getOrderAddonList),
  {
    method: 'GET',
    url: ({ orderId, useDefaults = true }) =>
      `${process.env.REACT_APP_API_URL}/orders/${orderId}/addons?useDefaultsIfNone=${useDefaults}`,
  }
);

// type GetAddonRes = { data: OrderAddon[] };
//
// export const getDefaultAddons = createCustomAsyncThunk<GetAddonRes, {}>(
//   createAsyncAction(sliceName, asyncActions.getDefaultAddons),
//   {
//     method: 'GET',
//     url: `${process.env.REACT_APP_API_URL}/addons`
//   }
// );

// POST
type PostOrderAddonListRes = { data: OrderAddon[] };
type PostOrderAddonListArgs = { orderId: number; data: { addons: number[] } };

export const postOrderAddonList = createCustomAsyncThunk<PostOrderAddonListRes, PostOrderAddonListArgs>(
  createAsyncAction(sliceName, asyncActions.postOrderAddonList),
  {
    method: 'POST',
    url: ({ orderId }) => `${process.env.REACT_APP_API_URL}/orders/${orderId}/addons`,
  }
);

// DELETE
type DeleteOrderItemListRes = { data: { count: number } };
type DeleteOrderItemListArgs = { orderId: number };

export const deleteOrderAddonList = createCustomAsyncThunk<DeleteOrderItemListRes, DeleteOrderItemListArgs>(
  createAsyncAction(sliceName, asyncActions.deleteOrderAddonList),
  {
    method: 'DELETE',
    url: ({ orderId }) => `${process.env.REACT_APP_API_URL}/orders/${orderId}/addons`,
  }
);

export const orderAddonsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    switchInclude(state, action: PayloadAction<{ id: number; isIncluded: boolean }>) {
      state.addons = state.addons.map((addon) =>
        addon.id === action.payload.id ? { ...addon, isIncluded: action.payload.isIncluded } : addon
      );
    },
  },
  extraReducers: (builder) =>
    builder
      // .addCase(getDefaultAddons.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(getDefaultAddons.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   state.defaultAddons = action.payload.data;
      // })
      // .addCase(getDefaultAddons.rejected, (state) => {
      //   state.status = 'failed';
      // })

      .addCase(getOrderAddonList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOrderAddonList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.addons = action.payload.data.map((addon) => ({
          ...addon,
          url: addon.staticFile
            ? createUrlToStaticFile({
                id: addon.staticFile.id,
                folderName: addon.staticFile.folderName,
                ext: addon.staticFile.extension,
              })
            : undefined,
        }));
      })
      .addCase(getOrderAddonList.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(postOrderAddonList.pending, (state) => {
        state.status = 'loading';
        state.isPostFinished = false;
      })
      .addCase(postOrderAddonList.fulfilled, (state) => {
        state.status = 'idle';
        state.isPostFinished = true;
      })
      .addCase(postOrderAddonList.rejected, (state) => {
        state.status = 'failed';
        state.isPostFinished = true;
      })

      .addCase(deleteOrderAddonList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteOrderAddonList.fulfilled, (state) => {
        state.status = 'idle';
        state.isPostFinished = true;
      })
      .addCase(deleteOrderAddonList.rejected, (state) => {
        state.status = 'failed';
        state.isPostFinished = true;
      }),
});

export const getIncludedOrderAddons = (state: RootState) =>
  state.orderAddons.addons.filter(({ isIncluded }) => isIncluded);

export const { switchInclude } = orderAddonsSlice.actions;

export const orderAddonsReducer = orderAddonsSlice.reducer;
