import { FC } from 'react';
import { Person } from '@styled-icons/evaicons-solid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery } from '../../JerseyConfig/hooks/query';
import { ActionButton } from '../../UI/ActionButton';
import { useAppSelector } from '../../hooks/reduxHooks';
import { RootState } from '../../../store/store';

const StyledSignInLink = styled(Link)`
  text-decoration: none;
  margin-left: auto;
`;

const StyledActionButton = styled(ActionButton)`
  text-transform: uppercase;
`;

export const SignInLink: FC = () => {
  const { t } = useTranslation(['home']);
  const query = useQuery();

  const firstName = useAppSelector((state: RootState) => state.accountSettings.firstName);

  const projectPathFromUrl = query.get('projectPath');

  return (
    <StyledSignInLink
      to={{ pathname: '/admin', search: projectPathFromUrl ? `?projectPath=${projectPathFromUrl}` : '' }}
      style={{marginLeft:"1em"}} id="signInMain">
      <StyledActionButton iconPosition="left">
        <Person id="loginImage" />
        <span id="loginLabel">{firstName || t('home:login')}</span>
      </StyledActionButton>
    </StyledSignInLink>
  );
};
