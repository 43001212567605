import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
/* import { matchJerseyConfigReducer } from "./slices/matchJerseyConfig"; */
import { jerseyConfigReducer } from '../pages/JerseyConfig/jerseyConfigSlice';
import { teamNameReducer } from '../pages/JerseyConfig/TeamNameConfig/teamNameSlice';
import { appReducer } from '../pages/App/appSlice';
import { textConfigReducer } from '../pages/JerseyConfig/TextConfig/textConfigSlice';
import { logoConfigReducer } from '../pages/JerseyConfig/LogoConfig/logoConfigSlice';
import { adminReducer } from '../pages/Admin/adminSlice';
import { patternReducer } from '../pages/JerseyConfig/Pattern/patternSlice';
import { addonsSliceReducer } from '../pages/JerseyConfig/AddonsConfig/addonsSlice';
import { accountSettingsReducer } from '../pages/Admin/Profile/AccountSettings/accountSettingsSlice';
import { priceInfoReducer } from '../pages/JerseyConfig/PriceInfo/priceInfoSlice';
import { orderReducer } from '../pages/JerseyConfig/OrderProcess/orderSlice';
import { fapiReducer } from '../pages/JerseyConfig/OrderProcess/fapiSlice';
import { orderJerseysReducer } from '../pages/JerseyConfig/OrderProcess/OrderJerseys/orderJerseysSlice';
import { orderAddonsReducer } from '../pages/JerseyConfig/OrderProcess/OrderAddons/orderAddonsSlice';
import { errorsReducer } from '../pages/errorsSlice';

export const store = configureStore({
  reducer: {
    matchJerseyConfig: jerseyConfigReducer,
    teamName: teamNameReducer,
    pattern: patternReducer,
    textConfig: textConfigReducer,
    logoConfig: logoConfigReducer,
    app: appReducer,
    admin: adminReducer,
    addons: addonsSliceReducer,
    accountSettings: accountSettingsReducer,
    priceInfo: priceInfoReducer,
    order: orderReducer,
    orderAddons: orderAddonsReducer,
    orderJerseys: orderJerseysReducer,
    fapi: fapiReducer,
    error: errorsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
