import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint } from '../utils';
import { Breakpoint } from '../../types';
import { useAppSelector } from '../hooks/reduxHooks';
import { selectIsAuth } from '../Admin/adminSlice';
import { Auth } from '../Admin/Auth/Auth';
import { useQuery } from '../JerseyConfig/hooks/query';
import useHttp from '../hooks/http';
import { Order } from '../Admin/types';
import { LoadingSpinner } from '../UI/LoadingSpinner';
import successImage from '../../assets/images/successful-order.png';
import ShareJerseyLinks from '../UI/ShareJerseyLinks';

type ResponsiveProps = {
  breakpoint: Breakpoint;
};

const StyledHeading = styled.h2<ResponsiveProps>`
  text-align: center;
  padding: 5% 1%;

  @media only screen and (min-width: ${`${breakpoint.tablet}px`}) {
    padding: 2%;
  }
`;

const StyledSpinnerWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;

type StyledStatus = {
  hasSucceeded: boolean;
};

const StyledStatusHeading = styled.h3<StyledStatus>`
  ${({ hasSucceeded }) => `
    color: var(${hasSucceeded ? '--color-success' : '--color-danger'});
  `};
`;

const StyledContent = styled.div<ResponsiveProps>`
  padding: 5% 5%;
  text-align: center;
  @media only screen and (min-width: ${`${breakpoint.tablet}px`}) {
    padding: 1% 5%;
  }
`;

const StyledPage = styled.div<ResponsiveProps>`
  background: var(--color-light);
  width: 90%;
  overflow: auto;
  margin: 2rem 5%;
  //margin: 5% 5%;
  padding: 1rem;
  border-radius: 5px;
  @media only screen and (min-width: ${`${breakpoint.tablet}px`}) {
    padding: 3rem;
    margin: 2rem 20%;
    min-width: 565px;
  }
`;

const requestIdentifiers = {
  getOrderById: 'getOrderById',
  getOrderByVariableSymbol: 'getOrderByVariableSymbol',
};

const StyledSuccessfulHeading = styled.h2`
  font-size: 3rem;
  text-align: center;
  @media only screen and (min-width: ${`${breakpoint.tablet}px`}) {
    font-size: 3rem;
  }
`;

const StyledSuccessfulText = styled.p`
  text-align: center;
  font-size: var(--font-size-medium);
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const StyledSuccessfulImageWrapper = styled.div`
  width: 100%;
`;

const StyledSuccessfulImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 250px;
  object-fit: cover;
`;

const StyledSuccessfulFooter = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  & > button:first-child {
    margin-right: 0.5rem;
  }
  & > button:last-child {
    margin-left: 0.5rem;
  }
`;

const StyledStartAgainLink = styled(Link)`
  color: var(--color-light);
  background-color: var(--color-success);
  text-decoration: none;
  //padding: 1rem;
  padding: 0.4rem 1.8rem;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 1px solid var(--color-success);
  margin-bottom: 1rem;
  &:focus {
    color: var(--color-success);
    background-color: var(--color-light);
    border: 1px solid var(--color-success);
  }
`;

type Props = {};

export const OrderStatus: FC<Props> = () => {
  const { t } = useTranslation(['order']);

  const { id: orderId } = useParams<{ id: string }>();

  const query = useQuery();
  const queryVs = query.get('vs');
  const autoRedirect = query.get('autoRedirect');

  const isAuth = useAppSelector(selectIsAuth); // TODO ještě autorizace, aby si to neotevřel jiný uživatel
  const device = useAppSelector((state) => state.app.device);

  const { data, error, isLoading, reqIdentifier, sendRequest } = useHttp<{ data: Order }>();
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [isStalling, setIsStalling] = useState<boolean>(false);

  useEffect(() => {
    if (queryVs) {
      setIsStalling(true);
    }
  }, [queryVs]);

  useEffect(() => {
    if (order) {
      const { fapiNextUrl, isPaid, isCancelled } = order;

      if (fapiNextUrl && !isPaid && !isCancelled && autoRedirect && !fapiNextUrl.includes(window.location.host)) {
        /* eslint-disable no-console */
        console.log(`Redirecting to ${order.fapiNextUrl}...`);

        const timer = setTimeout(() => {
          window.location.href = fapiNextUrl;
        }, 5000);

        return () => clearTimeout(timer);
      }
    }

    return () => {};
  }, [autoRedirect, order]);

  useEffect(() => {
    if (queryVs && isStalling) {
      setTimeout(() => {
        /* eslint-disable no-console */
        console.log('Waiting for payment status...');
        setIsStalling(false);
      }, 3000);
    }

    if (orderId) {
      sendRequest({
        url: `${process.env.REACT_APP_API_URL}/fapi/orders/${orderId}`,
        method: 'GET',
        body: null,
        reqIdentifer: requestIdentifiers.getOrderById,
      });
    } else if (queryVs) {
      sendRequest({
        url: `${process.env.REACT_APP_API_URL}/fapi/orders/${queryVs}?useVariableSymbol=true`,
        method: 'GET',
        body: null,
        reqIdentifer: requestIdentifiers.getOrderByVariableSymbol,
      });
    }
  }, [orderId, sendRequest, isStalling, queryVs]);

  useEffect(() => {
    if (reqIdentifier === requestIdentifiers.getOrderById && data && !error && !isLoading) {
      setOrder(data.data);
    } else if (reqIdentifier === requestIdentifiers.getOrderByVariableSymbol && data && !error && !isLoading) {
      setOrder(data.data);
    }
  }, [data, error, isLoading, reqIdentifier, sendRequest]);

  if (!order || !order?.project) {
    return (
      <StyledPage breakpoint={breakpoint}>
        <StyledSpinnerWrapper>
          <LoadingSpinner />
        </StyledSpinnerWrapper>
      </StyledPage>
    );
  }

  const successfullOrderContent = (
    <section>
      <StyledSuccessfulHeading>
        {device === 'mobile' ? t('order:orderStatus.scoreMobile') : t('order:orderStatus.scoreDesktop')}
      </StyledSuccessfulHeading>
      <StyledSuccessfulText>{t('order:orderStatus.thankYouMessage')}</StyledSuccessfulText>
      <StyledSuccessfulImageWrapper>
        <StyledSuccessfulImage src={successImage} alt="" />
      </StyledSuccessfulImageWrapper>
      <StyledSuccessfulFooter>
        <StyledStartAgainLink color="success" to="/">
          {t('order:orderStatus.startAgain')}
        </StyledStartAgainLink>
        <ShareJerseyLinks
          jerseyUrl={`${process.env.REACT_APP_BASE_URL}/share/${order.project.path}`}
          alignItems="center"
        />
      </StyledSuccessfulFooter>
    </section>
  );

  let content;

  if (isAuth) {
    const { fapiNextUrl, isPaid, isCancelled } = order;

    content = (
      <>
        <StyledHeading breakpoint={breakpoint}>{t('order:orderStatus.heading')}</StyledHeading>

        <StyledContent breakpoint={breakpoint}>
          {isCancelled ? (
            <StyledStatusHeading hasSucceeded={false}>{t('order:orderStatus.status.isCancelled')}</StyledStatusHeading>
          ) : (
            !autoRedirect &&
            (isPaid ? (
              successfullOrderContent
            ) : (
              <StyledStatusHeading hasSucceeded={true}>{t('order:orderStatus.status.isNotPaid')}</StyledStatusHeading>
            ))
          )}

          <br />

          {fapiNextUrl && !isPaid && !isCancelled && !fapiNextUrl.includes(window.location.host) && (
            <div>
              {autoRedirect && (
                <>
                  <p>{t('order:orderStatus.info.autoRedirect')}...</p>
                  <StyledSpinnerWrapper>
                    <LoadingSpinner />
                  </StyledSpinnerWrapper>
                  <br />
                </>
              )}

              <a href={fapiNextUrl}>{t('order:common.action.pay')}</a>
            </div>
          )}
        </StyledContent>
      </>
    );
  } else {
    content = (
      <>
        <StyledHeading breakpoint={breakpoint}>{t('order:common.loginPrompt')}</StyledHeading>
        <Auth /> {/* TODO potřeba pořešit background (dole) */}
      </>
    );
  }

  return (
    <StyledPage breakpoint={breakpoint}>
      {isStalling ? (
        <StyledSpinnerWrapper>
          <LoadingSpinner />
        </StyledSpinnerWrapper>
      ) : (
        content
      )}
    </StyledPage>
  );
};
