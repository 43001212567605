import { createSlice } from '@reduxjs/toolkit';
import { createAsyncAction, createCustomAsyncThunk } from '../../../utils';
import { OrderJersey } from './types';
import { RootState } from '../../../../store/store';

const sliceName = 'orderJersey';

const asyncActions = {
  getOrderJerseyList: 'getOrderJerseyList',
  postOrderJerseyList: 'postOrderJerseyList',
  patchOrderJerseyList: 'patchOrderJerseyList',
};

type OrderJerseysState = {
  status: 'idle' | 'loading' | 'failed';

  jerseys: OrderJersey[];
};

const initialState: OrderJerseysState = {
  status: 'idle',

  jerseys: [],
};

// GET
type GetOrderJerseyListRes = { data: OrderJersey[] };
type GetOrderJerseyListArgs = { orderId: number };

export const getOrderJerseyList = createCustomAsyncThunk<GetOrderJerseyListRes, GetOrderJerseyListArgs>(
  createAsyncAction(sliceName, asyncActions.getOrderJerseyList),
  {
    method: 'GET',
    url: ({ orderId }) => `${process.env.REACT_APP_API_URL}/orders/${orderId}/jerseys`,
  }
);

// POST
type PostOrderJerseyListRes = { data: OrderJersey[] };
type PostOrderJerseyListArgs = { orderId: number; data: { jerseys: OrderJersey[] } };

export const postOrderJerseyList = createCustomAsyncThunk<PostOrderJerseyListRes, PostOrderJerseyListArgs>(
  createAsyncAction(sliceName, asyncActions.postOrderJerseyList),
  {
    method: 'POST',
    url: ({ orderId }) => `${process.env.REACT_APP_API_URL}/orders/${orderId}/jerseys`,
  }
);

export const orderJerseysSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getOrderJerseyList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOrderJerseyList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.jerseys = action.payload.data;
      })
      .addCase(getOrderJerseyList.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(postOrderJerseyList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postOrderJerseyList.fulfilled, (state) => {
        state.status = 'idle';
        // state.items = action.payload.data;
      })
      .addCase(postOrderJerseyList.rejected, (state) => {
        state.status = 'failed';
      }),
});

// TODO dát jinam
const groupBy =
  <T>(keys: (keyof T)[]) =>
  (array: T[]): Record<string, T[]> =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = keys.map((key) => obj[key]).join('-');
      const objects = objectsByKeyValue;
      objects[value] = (objects[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {} as Record<string, T[]>);

export const getJerseysGroupedBySize = (state: RootState): OrderJersey[] => {
  if (state.orderJerseys.jerseys.length > 0) {
    const orderJerseys = state.orderJerseys.jerseys;

    const groupJerseysBySize = groupBy<OrderJersey>(['size']);
    const jerseyListBySize = groupJerseysBySize(orderJerseys);

    return Object.keys(jerseyListBySize).map((sizeGroup) => {
      const jerseys = jerseyListBySize[sizeGroup];
      const singleJersey = jerseys[0]; // za předpokladu, že všechny dresy stojí stejně

      return {
        ...singleJersey,
        amount: jerseys.length,
        price: singleJersey.price,
      };
    });
  }

  return [
    {
      number: null,
      name: null,
      size: 'XL',
      type: 'none',
      amount: 1,
      price: state.priceInfo.jerseyPriceSum.singleJersey ?? 0,
    },
  ];
};

type TrainingJerseysDefaults = Record<string, { size: string; amount: number }[]>;

export const getJerseysGroupedBySetId = (state: RootState): TrainingJerseysDefaults => {
  if (state.orderJerseys.jerseys.length > 0) {
    const orderJerseys = state.orderJerseys.jerseys;

    const groupJerseysBySetId = groupBy<OrderJersey>(['setId']);
    const jerseyListBySetId = groupJerseysBySetId(orderJerseys);

    const trainingJerseysFormDefaults: Record<string, { size: string; amount: number }[]> = {};

    Object.keys(jerseyListBySetId).forEach((setId) => {
      const group = groupBy<OrderJersey>(['size'])(jerseyListBySetId[setId]);
      trainingJerseysFormDefaults[setId] = Object.keys(group).map((size) => {
        const jerseys = group[size];
        return {
          size,
          amount: jerseys.length,
        };
      });
    });

    return trainingJerseysFormDefaults;
  }
  return {};
};

export const getJerseysGroupedByNumber = (state: RootState): OrderJersey[] => {
  if (state.orderJerseys.jerseys.length > 0) {
    const orderJerseys = state.orderJerseys.jerseys;

    const groupJerseysBySize = groupBy<OrderJersey>(['number']);
    const jerseyListBySize = groupJerseysBySize(orderJerseys);

    return Object.keys(jerseyListBySize).map((numberGroup) => {
      const jerseys = jerseyListBySize[numberGroup];
      const singleJersey = jerseys[0]; // za předpokladu, že všechny dresy stojí stejně

      return {
        ...singleJersey,
        amount: jerseys.length,
        price: singleJersey.price,
      };
    });
  }

  return [
    {
      number: '',
      name: '',
      size: 'XL',
      type: 'none',
      amount: 1,
      price: state.priceInfo.jerseyPriceSum.singleJersey ?? 0,
    },
  ];
};

export const resolveMatchJerseysFormDefaults = (state: RootState) => {
  const jerseysBySize = getJerseysGroupedByNumber(state);

  return jerseysBySize.map(({ number, name, size, type, amount }) => ({
    number,
    name,
    size: { id: size, name: size },
    type: { id: type ?? 'none', name: type },
    amount: amount ?? 0,
  }));
};

export const resolveTrainingJerseysFormDefaults = (state: RootState) => {
  const jerseysBySetId = getJerseysGroupedBySetId(state);

  const defaultValues: Record<string, string> = {};
  let index = 0;

  Object.keys(jerseysBySetId).forEach((key) => {
    const arr: [] = jerseysBySetId[key as any] as any;

    if (!Array.isArray(arr)) return;

    arr.forEach((item: any) => {
      defaultValues[`${index}-${item.size}`] = `${item.amount}`;
    });

    // eslint-disable-next-line no-plusplus
    index++;
  });

  return defaultValues;
};

export const countAddonsPerJersey = (state: RootState) =>
  state.orderAddons.addons.reduce((sum, addon) => {
    if (addon.isIncluded) return sum + addon.price;
    return sum;
  }, 0);

export const orderJerseysReducer = orderJerseysSlice.reducer;
