import { FC } from 'react';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import styled from 'styled-components';
import { CopyOutline } from '@styled-icons/evaicons-outline';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { breakpoint } from '../utils';

type AlignItems = 'flex-start' | 'center';

type StyledLinkWrapperProps = {
  alignitems: AlignItems;
};

const StyledLinkWrapper = styled.div<StyledLinkWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${({ alignitems }) => alignitems};
`;

// const StyledLink = styled.a`
//   overflow-wrap: break-word;
// `;

const StyledClipboard = styled.p`
  //overflow-wrap: break-word;
  border: 1px solid var(--color-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: var(--color-light-darken);
  }
  @media only screen and (min-width: ${`${breakpoint.tablet}px`}) {
    width: auto;
  }
`;

const StyledClipboardText = styled.span`
  //max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledCopyIcon = styled(CopyOutline)`
  margin-left: 3rem;
`;

const StyledShareWrapper = styled.div`
  display: flex;
`;

type Props = {
  jerseyUrl: string;
  alignItems?: AlignItems;
};

const ShareJerseyLink: FC<Props> = ({ jerseyUrl, alignItems = 'flex-start' }) => {
  const { t } = useTranslation(['ui']);

  const handleCopyJerseyLinkToClipboard = async (url: string) => {
    // clipboard only works with https or on localhost!
    if (!window.isSecureContext) {
      return;
    }
    await navigator.clipboard.writeText(url);

    toast(t('ui:shareLinks.linkCopied'), { toastId: 'copied', type: 'success', autoClose: 5000 });
  };

  return (
    <StyledLinkWrapper alignitems={alignItems}>
      <h4>{t('ui:shareLinks.shareOnSocialMedia')}</h4>
      <StyledShareWrapper>
        <FacebookShareButton url={jerseyUrl}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={jerseyUrl}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </StyledShareWrapper>
      <h4> {t('ui:shareLinks.shareLink')}:</h4>
      <StyledClipboard onClick={() => handleCopyJerseyLinkToClipboard(jerseyUrl)} role="button">
        <StyledClipboardText>{jerseyUrl}</StyledClipboardText>
        <StyledCopyIcon size={20} />
      </StyledClipboard>
    </StyledLinkWrapper>
  );
};

export default ShareJerseyLink;
