import { FC, ButtonHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { Breakpoint } from '../../types';
import { breakpoint } from '../utils';
import { Button } from './Button';

type RoundedButtonProps = {
  fill: 'outline' | 'full' | 'shadow';
  color: string;
  height?: string;
  className?: string;
  breakpoint: Breakpoint;
};

export const StyledRoundedButton = styled(Button)<RoundedButtonProps>`
  border-radius: 20px;
  height: ${({ height }) => height && height};
  color: ${({ fill, color }) =>
    fill === 'outline' || fill === 'shadow' ? `var(--color-${color})` : 'var(--color-light)'};
  border: ${({ fill, color }) => (fill === 'outline' ? `1px solid var(--color-${color})` : `1px solid transparent`)};
  background-color: ${({ fill, color }) => fill === 'full' && `var(--color-${color})`};
  display: flex;
  box-shadow: ${({ fill, color }) => fill === 'shadow' && `0px 0px 6px rgba(var(--color-${color}-rgb), 0.3)`};
  justify-content: center;
  align-items: center;
  padding: 0.4rem 1.8rem;
  &:active {
    color: ${({ fill, color }) =>
      fill === 'outline' || fill === 'shadow' ? 'var(--color-light)' : `var(--color-${color})`};
    background-color: ${({ fill, color }) =>
      fill === 'outline' || fill === 'shadow' ? `var(--color-${color})` : 'var(--color-light)'};
    border: ${({ fill, color }) => fill === 'full' && `1px solid var(--color-${color})`};
  }
  @media only screen and (min-width: ${`${breakpoint}px`}) {
    box-shadow: ${({ fill, color }) => fill === 'shadow' && `0px 0px 8px rgba(var(--color-${color}-rgb), 0.5)`};
  }
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  fill: 'outline' | 'full' | 'shadow';
  color: string;
  height?: string;
  className?: string;
  onClick?: any;
  children: ReactNode;
}

export const RoundedButton: FC<Props> = ({ children, className, color, height, fill, onClick, type, ...props }) => (
  <StyledRoundedButton
    className={className}
    color={color}
    height={height}
    fill={fill}
    onClick={onClick}
    type={type}
    {...props}
    breakpoint={breakpoint}
  >
    {children}
  </StyledRoundedButton>
);
