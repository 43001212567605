import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

type PatchProjectError = { code: number; data: { statusCode: number } };
type MandatoryFields = { path: string };

export const patchProject = <T extends MandatoryFields, U extends MandatoryFields>(actionName: string) =>
  createAsyncThunk<T, U, { rejectValue: PatchProjectError }>(
    actionName,
    async (patchParams: U, { rejectWithValue }) => {
      try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/projects/${patchParams.path}`, {
          ...patchParams,
        });
        return res.data.data as T;
      } catch (err) {
        let errors = err.response.data;

        if (!errors) errors = {};

        return rejectWithValue({
          data: errors,
          code: err.response.status,
        });
      }
    }
  );
