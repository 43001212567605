import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColorsRes, JerseyType, PatternPreviewsList, PatternRes } from '../types';
import { RootState } from '../../../store/store';
import { createAsyncAction, createCustomAsyncThunk, createUrlToStaticFile, fetchPatternById } from '../../utils';
import { fetchProjectByPath } from '../sharedActions';
import { patchProject } from '../reusableActions';

const resetState = createAction<null>('jerseyConfig/resetState');

const sliceName = 'pattern';
const asyncActions = {
  postProjectColor: 'postProjectColor',
  fetchPattern: 'fetchPattern',
  patchProjectPattern: 'patchProjectPattern',
  fetchInitPattern: 'fetchInitPattern',
  patchIsSetActive: 'patchIsSetActive',
  postTrainingSet: 'postTrainingSet',
  fetchInitColors: 'fetchInitColors',
  deleteTrainingSet: 'deleteTrainingSet',
  patchTrainingSet: 'patchTrainingSet',
};

export type Colors = {
  id: number;
  value: string;
  isActive: boolean;
  selectedColorId: number;
}[];

type PatternState = {
  status: 'idle' | 'loading' | 'failed';
  patternList: PatternPreviewsList;
  selectedPattern: {
    id: number | null;
    frontContent: string;
    backContent: string;
    activeColors: number;
    colors: Colors;
    activeColorIndex: number;
    // previewUrl: string;
  };
  selectedTrainingPattern: {
    id: number | null;
    frontContent: string;
    backContent: string;
    sets: Colors;
    activeSetIndex: number | null;
  };
};

const initialState: PatternState = {
  status: 'idle',
  patternList: [],
  selectedPattern: {
    id: null,
    frontContent: '',
    backContent: '',
    activeColors: 0,
    // previewUrl: '',
    colors: [
      // { id: 0, selectedColorId: 0, value: '#FFFFFF', isActive: false },
      // { id: 1, selectedColorId: 0, value: '#0000FF', isActive: false },
      // { id: 2, selectedColorId: 0, value: '#FF0000', isActive: false },
      // { id: 3, selectedColorId: 0, value: '#FFC0CB', isActive: false },
    ],
    activeColorIndex: 0,
  },
  selectedTrainingPattern: {
    id: null,
    frontContent: '',
    backContent: '',
    sets: [
      // { id: 0, selectedColorId: 0, value: '#FFFFFF', isActive: true }
    ],
    activeSetIndex: null,
  },
};

// HELPERS

const setPattern = (state: PatternState, payload: PatternRes) => {
  state.selectedPattern.id = payload.id;
  state.selectedPattern.frontContent = payload.svgFront;
  if (payload.svgBack) state.selectedPattern.backContent = payload.svgBack;
  state.selectedPattern.activeColors = payload.colorCount;

  if (state.selectedPattern.activeColorIndex > payload.colorCount) {
    state.selectedPattern.activeColorIndex = payload.colorCount - 1;
  }

  state.selectedPattern.colors = state.selectedPattern.colors.map((color: any) => {
    if (color.id < payload.colorCount) return { ...color, isActive: true };

    return { ...color, isActive: false };
  });
};

function createItemNameByLang<T extends object>(item: T, lang: string | undefined = 'cs'): string {
  const nameKey: keyof T = `name${lang.charAt(0).toUpperCase() + lang.slice(1)}` as keyof T;
  const name = item[nameKey];
  if (typeof name === 'string') return name as string;
  return '';
}

const createPatternOrSockList = (payload: {
  list: PatternRes[];
  selectedId: number;
}): { id: number; url: string; isSelected: boolean; name: string }[] =>
  payload.list.map((pattern) => {
    if (pattern.id === payload.selectedId) {
      return {
        id: pattern.id,
        // url: `${process.env.REACT_APP_STORAGE_URL}/${pattern.staticFile.storageName}`,
        url: createUrlToStaticFile({
          id: pattern.staticFile.id,
          folderName: pattern.staticFile.folderName,
          ext: pattern.staticFile.extension,
        }),
        isSelected: true,
        name: createItemNameByLang(pattern),
      };
    }
    return {
      id: pattern.id,
      url: createUrlToStaticFile({
        id: pattern.staticFile.id,
        folderName: pattern.staticFile.folderName,
        ext: pattern.staticFile.extension,
      }),
      isSelected: false,
      name: createItemNameByLang(pattern),
    };
  });

// END OF HEPLERS

// THUNKS

type PostMatchJerseyColorParams = {
  path: string;
  activeColorIndex: number;
  jerseyType: JerseyType;
  data: {
    colorId: number;
  };
};

type PostMatchJerseyColorRes = {
  id: number;
  colorId: number;
  projectId: number;
  order: number;
  color: ColorsRes;
  jerseyType: JerseyType;
};

export const postMatchJerseyColor = createCustomAsyncThunk<PostMatchJerseyColorRes, PostMatchJerseyColorParams>(
  createAsyncAction(sliceName, asyncActions.postProjectColor),
  {
    method: 'POST',
    url: ({ path, activeColorIndex }) => `${process.env.REACT_APP_API_URL}/projects/${path}/match/${activeColorIndex}`,
  }
);

type JerseyPatternArg = number;
export const fetchPattern = createAsyncThunk<PatternRes, JerseyPatternArg>(
  createAsyncAction(sliceName, asyncActions.fetchPattern),
  async (patternId) => {
    const patternRes = await fetchPatternById(patternId);
    return patternRes.data.data;
  }
);

type PatchProjectPatternParams = {
  path: string;
  patternId: number;
  toIncludeInRes: 'pattern';
};

type PatchProjectPatternRes = PatchProjectPatternParams & {
  pattern: PatternRes;
};

export const patchProjectPattern = patchProject<PatchProjectPatternRes, PatchProjectPatternParams>(
  createAsyncAction(sliceName, asyncActions.patchProjectPattern)
);

type FetchInitPatternRes = { data: PatternRes[] };

type FetchInitPatternParams = { jerseyTypeId: string };

export const fetchInitPattern = createCustomAsyncThunk<FetchInitPatternRes, FetchInitPatternParams>(
  createAsyncAction(sliceName, asyncActions.fetchInitPattern),
  {
    method: 'GET',
    url: ({ jerseyTypeId }) => `${process.env.REACT_APP_API_URL}/patterns?type=${jerseyTypeId}Jersey&isDefault=true`,
  }
);

type FetchInitColorParams = {
  jerseyTypeId: JerseyType;
  colorCount: number;
  isDefault?: boolean;
};

type FetchInitColorRes = {
  data: {
    id: number;
    value: string;
    nameCs: string;
    nameEn: string;
    isDefault: boolean;
    isDeleted: boolean;
  }[];
  jerseyTypeId: JerseyType;
};

export const fetchInitColors = createCustomAsyncThunk<FetchInitColorRes, FetchInitColorParams>(
  createAsyncAction(sliceName, asyncActions.fetchInitColors),
  {
    method: 'GET',
    url: ({ colorCount, isDefault }) =>
      `${process.env.REACT_APP_API_URL}/colors?${isDefault ? 'isDefault=true&' : ''}colorCount=${colorCount}`,
  }
);

type PostTrainingSetParams = {
  path: string;
  // setId: number;
  data: {
    colorId: number;
  };
};

type PostTrainingSetRes = {
  path: string;
  // setId: number;
  id: number;
  colorId: number;
  color: ColorsRes;
};

export const postTrainingSet = createCustomAsyncThunk<PostTrainingSetRes, PostTrainingSetParams>(
  createAsyncAction(sliceName, asyncActions.postTrainingSet),
  {
    method: 'POST',
    url: ({ path }) => `${process.env.REACT_APP_API_URL}/projects/${path}/training`,
  }
);

type DeleteTrainingSetParams = {
  path: string;
  projectColorId: number;
};

type DeleteTrainingSetRes = {
  path: string;
  projectColorId: number;
};

export const deleteTrainingSet = createCustomAsyncThunk<DeleteTrainingSetRes, DeleteTrainingSetParams>(
  createAsyncAction(sliceName, asyncActions.deleteTrainingSet),
  {
    method: 'DELETE',
    url: ({ path, projectColorId }) => `${process.env.REACT_APP_API_URL}/projects/${path}/training/${projectColorId}`,
  }
);

type PatchTrainingSetParams = {
  path: string;
  projectColorId: number;
  data: {
    colorId: number;
  };
};

type PatchTrainingSetRes = {
  path: string;
  projectColorId: number;
  colorId: number;
  color: {
    value: string;
  };
};

export const patchTrainingSet = createCustomAsyncThunk<PatchTrainingSetRes, PatchTrainingSetParams>(
  createAsyncAction(sliceName, asyncActions.patchTrainingSet),
  {
    method: 'PATCH',
    url: ({ path, projectColorId }) => `${process.env.REACT_APP_API_URL}/projects/${path}/training/${projectColorId}`,
  }
);

// END OF THUNKS

export const patternSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    changePatternActiveColorIndex(state, action: PayloadAction<number>) {
      state.selectedPattern.activeColorIndex = action.payload;
    },
    changeTrainingPatternActiveIndexSet(state, action: PayloadAction<number>) {
      if (state.selectedTrainingPattern.activeSetIndex === action.payload)
        state.selectedTrainingPattern.activeSetIndex = null;
      else state.selectedTrainingPattern.activeSetIndex = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(resetState, (_, _1) => initialState)
      .addCase(patchProjectPattern.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchProjectPattern.fulfilled, (state, action) => {
        state.status = 'idle';
        const { pattern: patternRes } = action.payload;
        setPattern(state, patternRes);
        state.patternList = state.patternList.map((pattern) => {
          if (pattern.id === action.payload.patternId) return { ...pattern, isSelected: true };
          return { ...pattern, isSelected: false };
        });
      })
      .addCase(fetchInitPattern.fulfilled, (state, action) => {
        if (action.payload.data && action.payload.data.length > 0) {
          if (!state.selectedPattern.id) {
            setPattern(state, action.payload.data[0]);
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('No default pattern!');
        }
      })
      .addCase(fetchInitColors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInitColors.fulfilled, (state, action) => {
        state.status = 'idle';
        const { jerseyTypeId, data } = action.payload;
        if (jerseyTypeId === 'training') {
          const [firstSet] = data;
          state.selectedTrainingPattern.sets.push({
            id: 0,
            selectedColorId: firstSet.id,
            value: firstSet.value,
            isActive: true,
          });
        } else {
          const jerseyColors = data.map((color, index) => ({
            id: index,
            selectedColorId: color.id,
            value: color.value,
            isActive: index === 0,
            isDefault: color.isDefault,
          }));

          const defaultColorIndex = jerseyColors.findIndex((c) => c.isDefault);

          jerseyColors.unshift(jerseyColors.splice(defaultColorIndex, 1)[0]);

          state.selectedPattern.colors = jerseyColors.map((color, index) => ({
            id: index,
            selectedColorId: color.id,
            value: color.value,
            isActive: color.isActive,
          }));
        }
      })
      .addCase(fetchProjectByPath.fulfilled, (state, action) => {
        const { projectColors, pattern: patternRes, patterns: patternsRes, projectType } = action.payload;
        const fetchedProjectColors = projectColors.map((color) => ({
          // order is not important for trainig jersey
          // that was not an original plan, so same hacky stuff needed to be done
          // that is why for training jersey, there is a color.id instead of a color.order
          id: projectType === 'match' ? color.order : color.id,
          selectedColorId: color.colorId,
          value: color.color.value,
          isActive: color.isActive,
        }));

        if (projectType === 'match') {
          state.selectedPattern.colors.splice(0, fetchedProjectColors.length, ...fetchedProjectColors);
        } else {
          state.selectedTrainingPattern.sets = fetchedProjectColors;
        }

        state.patternList = createPatternOrSockList({
          list: patternsRes,
          selectedId: patternRes.id,
        });
        setPattern(state, patternRes);
      })
      .addCase(postMatchJerseyColor.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postMatchJerseyColor.fulfilled, (state, action) => {
        state.status = 'idle';
        const {
          color: { id, value },
          order: activeColorIndex,
        } = action.payload;

        state.selectedPattern.colors = state.selectedPattern.colors.map((color, index) => {
          if (index !== activeColorIndex) return color;
          return {
            ...state.selectedPattern.colors[state.selectedPattern.activeColorIndex],
            selectedColorId: id,
            value,
          };
        });
      })
      .addCase(postTrainingSet.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postTrainingSet.fulfilled, (state, action) => {
        state.status = 'idle';
        const {
          color: { id: selectedColorId, value },
          id,
        } = action.payload;

        state.selectedTrainingPattern.sets.push({
          id,
          value,
          isActive: true,
          selectedColorId,
        });
      })
      .addCase(patchTrainingSet.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchTrainingSet.fulfilled, (state, action) => {
        state.status = 'idle';
        const { colorId, projectColorId, color } = action.payload;
        state.selectedTrainingPattern.sets = state.selectedTrainingPattern.sets.map((set) => {
          if (set.id !== projectColorId) return set;
          return {
            ...set,
            selectedColorId: colorId,
            value: color.value,
          };
        });
      })
      .addCase(deleteTrainingSet.pending, (state) => {
        state.status = 'idle';
      })
      .addCase(deleteTrainingSet.fulfilled, (state, action) => {
        const { projectColorId } = action.payload;

        state.selectedTrainingPattern.sets = state.selectedTrainingPattern.sets.filter(
          (set) => set.id !== projectColorId
        );
      }),
});

export const selectActiveTrainingSet = (state: RootState) => {
  const activeSetId = state.pattern.selectedTrainingPattern.activeSetIndex;
  return (
    state.pattern.selectedTrainingPattern.sets.find((set) => set.id === activeSetId) ??
    state.pattern.selectedTrainingPattern.sets[0]
  );
};

export const selectNumberOfActiveSets = (state: RootState) =>
  state.pattern.selectedTrainingPattern.sets.filter((set) => set.isActive).length;

export const findSelectedMatchPatternPreview = (state: RootState) => {
  const selectedPreview = state.pattern.patternList.find((preview) => preview.id === state.pattern.selectedPattern.id);
  if (!selectedPreview) return '';
  return selectedPreview.url;
};
// I need to listen in init vals...

export const { changePatternActiveColorIndex, changeTrainingPatternActiveIndexSet } = patternSlice.actions;

export const patternReducer = patternSlice.reducer;
