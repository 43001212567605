import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Input } from '../../UI/Input';
import { RoundedButton } from '../../UI/RoundedButton';
import useHttp from '../../hooks/http';
import { getApiErrorMessage } from '../../utils';
import { LoadingSpinnerOverlay } from '../../UI/LoadingSpinnerOverlay';

const StyledPerformResetPassword = styled.form`
  background-color: var(--color-light);
  margin: auto;
  padding: 3rem;
  & > * {
    margin-bottom: 2rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const StyledSuccessMessage = styled.p`
  font-size: var(--font-size-medium);
  color: var(--color-success);
  font-weight: bold;
`;

const StyledForgottenPasswordLink = styled(Link)`
  color: var(--color-medium);
  margin-top: 2rem;
  font-weight: bold;
`;

const StyledErrorMessage = styled.p`
  color: var(--color-danger);
`;

const StyledLink = styled(Link)`
  color: var(--color-primary);
`;

type FormData = {
  password: string;
  passwordAgain: string;
};

const requestIdentifiers = {
  postPasswordReset: 'postPasswordReset',
};

const PerformResetPassword = () => {
  const { t } = useTranslation(['admin', 'resetPassword']);

  const { sendRequest, data, error, isLoading } = useHttp();

  const { resetToken } = useParams<{ resetToken: string }>();

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        password: yup
          .string()
          .required(t('admin:accSettings.passwordIsRequired'))
          .min(8, t('admin:accSettings.passwordLengthErr')),
        passwordAgain: yup
          .string()
          .required(t('admin:accSettings.passwordRepeatIsRequired'))
          .oneOf([yup.ref('password'), null], t('admin:auth.passwordsMustMatch')),
      })
    ),
  });

  const onSubmit = handleSubmit(async (formData) => {
    await sendRequest({
      url: `${process.env.REACT_APP_API_URL}/reset-password/${resetToken}`,
      method: 'POST',
      body: {
        password: formData.password,
        passwordAgain: formData.passwordAgain,
      },
      reqIdentifer: requestIdentifiers.postPasswordReset,
    });
  });

  return (
    <StyledPerformResetPassword onSubmit={onSubmit}>
      {data ? (
        <StyledSuccessMessage>
          {t('resetPassword:resetSuccessful')}
          <br />
          {t('resetPassword:toSignInClick')}
          <StyledLink to="/admin/auth">{t('resetPassword:here')}</StyledLink>
        </StyledSuccessMessage>
      ) : (
        <>
          <h2>{t('resetPassword:performPasswordReset')}</h2>
          <Input
            type="password"
            placeholder={t('resetPassword:passwordLabel')}
            register={register}
            name="password"
            errors={errors}
            label={t('resetPassword:passwordPlaceholder')}
          />
          <Input
            type="password"
            placeholder={t('resetPassword:passwordAgainPlaceholder')}
            register={register}
            name="passwordAgain"
            errors={errors}
            label={t('resetPassword:passwordAgainLabel')}
          />
          <RoundedButton fill="full" type="submit" color="primary">
            {t('resetPassword:changePasswordButton')}
          </RoundedButton>
          {error && <StyledErrorMessage>{getApiErrorMessage(error.message, t)}</StyledErrorMessage>}
          <StyledForgottenPasswordLink to="/reset-password">
            {t('resetPassword:requestPasswordResetAgain')}
          </StyledForgottenPasswordLink>
        </>
      )}
      {isLoading && <LoadingSpinnerOverlay />}
    </StyledPerformResetPassword>
  );
};

export default PerformResetPassword;
