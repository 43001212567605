import { createSlice, isRejected, PayloadAction } from '@reduxjs/toolkit';

type ErrorsState = {
  message?: string;
  statusCode?: number;
};

const initialState: ErrorsState = {
  message: '',
  statusCode: undefined,
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    clearErrors(state) {
      state.message = '';
      state.statusCode = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isRejected, 
      (state, action: PayloadAction<{ data?: { message?: string; statusCode?: number } }|unknown>) => {
        if (action.payload && action.payload) {
          state.message = 'Erro';
          state.statusCode = 400;
        }
      }
    );
  },
});

export const { setError, clearErrors } = errorsSlice.actions;

export const errorsReducer = errorsSlice.reducer;
